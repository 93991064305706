<template>
  <div>
    <div v-show="display" class="loading_overlay_bg">
      <div class="ks_spinner_container">
        <i class="fa fa-spinner fa-spin fa-5x"> </i>
        <p class="ks_spinner_text">{{ text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'spinner',
  data() {
    return {
      display: false,
      text: null
    }
  },
  methods: {
    show(txt = null) {
      this.text = txt;
      this.display = true;
    },
    hide() {
      this.text = null;
      this.display = false;
    }
  }
}
</script>

<style type="text/css">
  .ks_spinner_text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25px;
    font-weight: bold;
  }
  .ks_spinner_container {
    text-align: center;
    z-index: 99999999;
  }
  .loading_overlay {
    position: relative;
  }
  .loading_overlay_bg {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
    background: rgba(0, 0, 0, 0.1);
  }
</style>