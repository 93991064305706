<template>
  <div class="container" style="margin-top:15%">
    <header-menu/>
    <div class="row">
      <div class="col-md-4 offset-md-4 shadow p-3 mb-5 bg-body rounded">
        <img src="../../assets/images/full-logo.png" class="img-fluid" title="South Florida School of Real Estate" alt="South Florida School of Real Estate"/>
        <form id="loginForm" @submit.prevent="login">
          <div class="form-group">
            <input type="email" class="form-control" id="user_email" placeholder="Your Email address" v-model="user.email" required>
            <div class="invalid-feedback">
              Please provide a valid Email.
            </div>
          </div>
          <div class="form-group mt-2">
            <div class="input-group">
              <input
                v-show="pType == 'password'" 
                type="password" 
                class="form-control" 
                id="user_password" 
                placeholder="**************" 
                v-model="user.password" 
                required
              >
              <input
                v-show="pType == 'text'" 
                type="text" 
                class="form-control" 
                id="user_password" 
                placeholder="**************" 
                v-model="user.password" 
                required
              >
              <span class="input-group-text" @click.prevent="pType = switchType(pType)">&nbsp<i class="fa-solid fa-eye"></i> </span>
            </div>
          </div>
          <div class="row col-12 text-danger" v-show="status.fail">
            <strong> {{ status.msg }} </strong> 
          </div> 
          <a href="/recovery" class="btn btn-link" style="float: right;"> Forgot Password? </a>
          <button style="margin-top:10px" type="submit" @click="addValidation" class="w-100 btn btn-block btn-primary"> Log in </button>
        </form>
        <a href="/register" class="btn btn-link"> Register Now</a>
      </div>
    </div>
    <footer class="fixed-bottom container-fluid text-bg-light">
      <p class="text-center">South Florida School of Real Estate© 2023 • All Rights Reserved <span class="text-muted ml-4">v1.0.0</span></p>
    </footer>
  </div>
</template>

<script>
import request from './../../helpers/request'
import headerMenu from './headerMenu'

export default {
  name: 'login',
  components:{
    'header-menu' : headerMenu
  },
  methods:{
    switchType(currentType){
      if(currentType === 'password') return 'text';
      else return 'password';
    },
    addValidation(){
      let form = document.getElementById("loginForm");
      form.classList.add('was-validated');
    },
    async login(){
      try{
        const res = await request('POST','/login',this.user);
        if(res.token){
          this.$store.dispatch("auth/login", res.token);
          this.$router.push('/');
          //;
        }else{
          throw "Invalid Token";
        }
      }catch(err){
        this.status.fail = true;
        this.status.msg = "E-mail and/or Password invalid";
        setTimeout(()=>{ 
          this.status.fail = false;
          this.status.msg = "";
        }, 5000);	
      }
    }
  },
  data(){
    return {
      pType: 'password',
      status:{
				fail : false,
        msg : "",
      },
      user:{
        email: null,
        password: null
      }
    }
  }
}
</script>
