<template>
    <form id="courseForm" @submit.prevent="submitCourseForm" class="row">
        <div class="accordion col-12" id="courseInfo" style="margin-top:15px">
            <div class="accordion-item">
                <h2 class="accordion-header" id="generalHeader">
                <button class="accordion-button" style="background-color:#f1f1f1" type="button" data-bs-toggle="collapse" data-bs-target="#generalCollapse" aria-expanded="true" aria-controls="generalCollapse">
                    General
                </button>
                </h2>
                <div id="generalCollapse" class="accordion-collapse collapse show" aria-labelledby="generalHeader" >
                    <div class="row accordion-body">

                        <div class="mb-3 col-md-8 col-12" v-if="courseId === null || course.courseParentId">
                            <label for="course_courseParentId">Extend Contents From</label>
                            <select class="form-control" id="course_courseParentId" v-model="course.courseParentId" @change="setCourse" :disabled="courseId">
                                <option :value="null">None</option>
                                <option v-for="course of courses" :value="course._id">{{course.fullName}}</option>
                            </select>
                        </div>

                        <div class="mb-3 col-md-8 col-12">
                            <label for="course_fullName">Course Name <span style="color:red">*</span></label>
                            <input type="text" class="form-control" id="course_fullName" minlength="2" v-model="course.fullName" autocomplete="new-password" required>
                            <div class="invalid-feedback">
                                Please provide a valid Course Name.
                            </div>
                        </div>

                        <div class="mb-3 col-md-4 col-12">
                            <label for="course_shortName">Course Short Name</label>
                            <input type="text" class="form-control" id="course_shortName" v-model="course.shortName" autocomplete="new-password">
                        </div>

                        <div class="mb-3 col-md-8 col-12">
                            <label for="course_price">$ Price <span style="color:red">*</span> </label>
                            <input type="number" class="form-control" id="course_shortName" v-model="course.price" autocomplete="new-password" step="0.01" required>
                            <div class="invalid-feedback">
                                Please provide a valid Course Price.
                            </div>
                        </div>

                        <div class="mb-3 col-md-4 col-12">
                            <label for="course_weight">Weight</label>
                            <input type="number" step="any" class="form-control" id="course_weight" v-model="course.weight" autocomplete="new-password">
                        </div>

                        <div class="mb-3 col-12">
                            <input class="form-check-input" type="checkbox" v-model="course.active" id="course_active">
                            <label class="form-check-label" for="course_active">&nbsp&nbsp Active </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="descriptionHeader">
                    <button class="accordion-button" style="background-color:#f1f1f1" type="button" data-bs-toggle="collapse" data-bs-target="#descriptionCollapse" aria-expanded="true" aria-controls="descriptionCollapse">
                        Description
                    </button>
                </h2>
                <div id="descriptionCollapse" class="accordion-collapse collapse show" aria-labelledby="descriptionHeader">
                    <div class="row accordion-body">

                        <select class="form-select col-12 col-md-4" v-model="course.descriptionType">
                            <option value="editor">Editor</option>
                            <option value="html">Embed HTML</option>
                        </select>
  
                        <editor
                            class="mt-3" 
                            v-if="course.descriptionType == 'editor'"
                            v-model:value="course.description"
                            id="course-description-editor"
                            :customToolbar="customToolbar"
                        />

                        <div class="mt-3"
                            v-else
                        >
                            <textarea class="form-control" id="course_embedHTML" rows="10" v-model="course.description"></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="imageHeader">
                    <button class="accordion-button" style="background-color:#f1f1f1" type="button" data-bs-toggle="collapse" data-bs-target="#imageCollapse" aria-expanded="true" aria-controls="imageCollapse">
                        Image
                    </button>
                </h2>
                <div id="imageCollapse" class="accordion-collapse collapse show" aria-labelledby="imageHeader">
                    <div class="row accordion-body">
                        <div class="mb-3 mt-3 col-12 col-md-4" v-if="courseId && courseImages.length > 0">
                            <template v-if="!enableUpdateImage">
                                <file-list id="courseUploadedFileList" :files="courseImages" :enableViewer="true" :enableDelete="true"  @delete="enableUpdateImage = true"/>
                            </template>
                            <template v-else>
                                <label style="margin-top:10px">Add New Image</label>
                                <file-input @onInput="course.files = $event" accept="image/*" :multiple="false" ref="course-input-files"/>
                            </template>
                        </div>

                        <div class="mb-3 col-12 col-md-4" v-else>
                            <label style="margin-top:10px">Add New Image</label>
                            <file-input @onInput="course.files = $event" accept="image/*" :multiple="false" ref="course-input-files"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 clearfix" style="margin-top:15px">
            <button class="btn btn-danger float-end" style="margin-left:5px" @click.prevent="$emit('onCancel', $event)">Cancel <i class="fa-solid fa-xmark"></i> </button>
            <button type="submit" class="btn btn-primary float-end" @click="addValidation" > Save <i class="far fa-save"></i></button>
        </div>

        <spinner ref="courseFormSpinner"/>
    </form>
</template>

<script>
import request from '../../../../helpers/request'
import spinner from '../../../utilities/spinner'
import Vuex from 'vuex'
import validateRole from '../../../../mixins/validateRole'
import editor from '../../../utilities/editor';
import fileInput from '../../../utilities/fileInput';
import fileList from '../../../utilities/fileList';

export default {
    name: "courseForm",
    mixins: [validateRole],
    components:{
        'spinner':  spinner,
        'editor': editor,
        'file-input': fileInput,
        'file-list': fileList
    },
    watch:{
        courseId: function(val){
            if(val!== null){
                this.getCourse();
            }else{
                this.newCourse();
            }
        }
    },
    computed:{
		...Vuex.mapState({
            authUser: state => state.auth.user,
            authToken: state => state.auth.token,
        }),
        type(){
            return this.courseId?'edit':'create';
        }
    },
    mounted(){
        if(this.courseId){
            this.getCourse();
        }
        this.getCourses();
    },
    props:{
        courseId:{
            type: String,
            default: null
        }
    },
    methods:{
        setCourse(evt){
            if(evt.target.value != null && evt.target.value != 'None'){
                this.getCourse(evt.target.value);
            }else{
                this.newCourse();
            }
        },
        addValidation(){
            let form = document.getElementById("courseForm");
            form.classList.add('was-validated');
        },
        async submitCourseForm(){
            try{
                let res;
                this.$refs.courseFormSpinner.show();
                let formData = new FormData();
                if(this.course.files && this.course.files.length > 0){
                    for(let file of this.course.files){
                        formData.append('files',file);
                    }
                }
                delete this.course.image;
                delete this.course.files;
                delete this.course._id;
                if(!this.course.courseParentId) delete this.course.courseParentId;
                for(let key in this.course){ formData.append(key,this.course[key]); }
                if(this.courseId){
                    formData.append('courseId',this.courseId);
                    res = await request('POST', `/course/update?token=${this.authToken}`, formData, null, true);
                }else{
                    res = await request('POST', `/course/create?token=${this.authToken}`, formData, null, true);
                }
                this.$emit('onSave', {id:res._id, type:this.type});
                this.$refs.courseFormSpinner.hide();
            }catch(err){
                console.log(err);
                this.$emit('onError', err);
                this.$refs.courseFormSpinner.hide();
            }
        },
        async getCourse(courseId = null){
            try{
                this.$refs.courseFormSpinner.show();
                let res = await request('POST', '/course/read', { 'courseId': courseId ? courseId : this.courseId }, this.authToken);
                if(courseId) res._id = null;
                this.course = {...this.course, ...res};
                this.courseImages = this.course.image?[{src:`${app.API}/${this.course.image}`, name: this.course.image}]:[];
                this.$refs.courseFormSpinner.hide();
                this.$emit('onRead', this.course);
            }catch(err){
                console.log(err);
            }
        },
        newCourse(){
            this.course={
                _id: null,
                fullName: '',
                shortName: '',
                firstName: '',
                active: true,
                description: '',
                files: [],
                price: null,
                image: null,
                courseParentId: null,
                weight: null
            };
            this.courseImages = [];
        },
        async getCourses(){
            try{
                let res = await request('POST', '/course/list' ,  {
                    filters: {}, 
                    limit: 9999,
                    sort: null, 
                    skip: 0,
                    search: null,
                    pagination: true 
                }, 
                this.authToken);
                this.courses = res.records.filter(c => !c.courseParentId);
            }catch(err){
                console.log(err);
            }
        }
    },
    data(){
        return{
            enableUpdateImage: false,
            courseImages: [],
            courses: [],
            course:{
                _id: null,
                fullName: '',
                shortName: '',
                firstName: '',
                active: true,
                description: '',
                descriptionType: 'editor',
                embedHTML:'',
                price: null,
                image: null,
                courseParentId: null,
                weight: null
            },
            customToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["code-block"]
            ]
        }
    }
}
</script>