<template>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <router-link :to="`/course/view/${courseId}`">
                    {{  strCUT(courseName, 7) }}
                </router-link>
            </li>
            <template v-if="content">
                <li class="breadcrumb-item" v-for="ct in content.contentParents">
                    <router-link :to="`/course/view/${courseId}/${ct._id}`">
                        {{ strCUT(ct.name,7) }}
                    </router-link>
                </li>
                <li class="breadcrumb-item active">
                    {{ strCUT(content.name,7) }}
                </li>
            </template>
        </ol>
    </nav>
</template>
<script>
export default {
    name: 'course-breadcrum',
    props:{
        courseId:{
            type : String,
            required: true
        },
        courseName:{
            type : String,
            required: true
        },
        content:{
            type : Object,
            default: null
        }
    },
    computed:{
        size(){
            if (window.innerWidth <= 575) {
                return 'xs'
            } else if (window.innerWidth >= 576 && window.innerWidth <= 767) {
                return 'sm'
            } else if (window.innerWidth >= 768 && window.innerWidth <= 992) {
                return 'md'
            } else if (window.innerWidth >= 993 && window.innerWidth <= 1200) {
                return 'lg'
            } else {
                return 'xl'
            }
        }
    },
    methods:{
        strCUT(str='', size=10){
            console.log(this.size);
            if(this.size == 'xs' || this.size == 'sm'  ){
                return str.length > size ? `${str.substr(0,size-1)}...` : str;
            }
            return str;
        }
    }
}
</script>
