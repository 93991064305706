<template>
    <form id="userForm" @submit.prevent="submitUserForm">
        <div style="margin-top:15px">
            <div class="card">
                <h5 class="card-header"> Account </h5>
                <div class="card-body row">
                    <div class="mb-3 col-md-4 col-12">
                        <label for="user_email">Email <span style="color:red">*</span> </label>
                        <input type="email" class="form-control" placeholder="Your Email address" id="user_email" v-model="user.email" autocomplete="new-password" required>
                        <div class="invalid-feedback d-block" v-if="emailError"> Registered Email </div>
                    </div>

                    <template v-if="showPassword">
                        <div class="mb-3 col-md-4 col-12">
                            <label for="user_password">Password <span style="color:red" v-if="type == 'create'">*</span> </label>
                            <div class="input-group">
                                <input
                                    v-show="pType == 'password'" 
                                    type="password" 
                                    class="form-control" 
                                    id="user_password" 
                                    placeholder="**************" 
                                    v-model="user.password" 
                                    autocomplete="new-password"
                                    minlength="6" 
                                    :required="type == 'create' || !editPassword" 
                                    :disabled="type == 'edit'? editPassword : false "
                                >
                                <input
                                    v-show="pType == 'text'" 
                                    type="text" 
                                    minlength="6"
                                    class="form-control" 
                                    id="user_password" 
                                    placeholder="**************" 
                                    v-model="user.password" 
                                    autocomplete="new-password" 
                                    :required="type == 'create' || !editPassword" 
                                    :disabled="type == 'edit'?editPassword:false"
                                >
                                    <span class="input-group-text" @click.prevent="pType = switchType(pType)" v-show="type=='create' || !editPassword">&nbsp<i class="fa-solid fa-eye"></i> </span>
                                    <span class="input-group-text" @click.prevent="editPassword=!editPassword;user.password=verifyPassword=null;" v-if="type=='edit'">&nbsp<i class="fa-solid fa-pen"></i> </span>
                            </div>
                        </div>

                        <div class="mb-3 col-md-4 col-12" v-if="type == 'create' || !editPassword">
                            <label>Confirm Password <span style="color:red">*</span> </label>
                            <div class="input-group">
                                <input
                                    v-show="cPType == 'password'"  
                                    type="password" 
                                    class="form-control" 
                                    id="verifyPassword1" 
                                    placeholder="*******************" 
                                    v-model="verifyPassword" 
                                    autocomplete="new-password" 
                                    required
                                >
                                <input
                                    v-show="cPType == 'text'"  
                                    type="text" 
                                    class="form-control" 
                                    id="verifyPassword2" 
                                    placeholder="*******************" 
                                    v-model="verifyPassword" 
                                    autocomplete="new-password" 
                                    required
                                >
                                <span class="input-group-text" @click.prevent="cPType = switchType(cPType)">&nbsp<i class="fa-solid fa-eye"></i> </span>
                            </div>
                            <div class="invalid-feedback d-block" v-if="vpError"> The password Confirmation does not match </div>
                        </div>
                    </template>

                    <div class="mb-3 col-md-6 col-12" v-if="validateRole(1)">
                        <label for="user_role">Roles</label>
                        <select id="user_role" class="form-control" v-model="user.role" required>
                            <option :value="1">Administrador</option>
                            <option :value="2">Student</option>
                        </select>
                    </div>

                    <div class="mb-3 col-12" v-if="validateRole(1)">
                        <input class="form-check-input" type="checkbox" v-model="user.active" id="user_active">
                        <label class="form-check-label" for="user_active">&nbsp&nbsp Active Account </label>
                    </div>
                </div>
            </div>

            <div class="card mt-3">
                <h5 class="card-header">General</h5>
                <div class="card-body row">
                    <div class="mb-3 col-md-4 col-12">
                        <label for="user_firstName">First Name <span style="color:red">*</span></label>
                        <input type="text" class="form-control" id="user_firstName" v-model="user.firstName" required>
                        <div class="invalid-feedback">
                            Please provide a valid First Name.
                        </div>
                    </div>

                    <div class="mb-3 col-md-4 col-12">
                        <label for="user_middleName">Middle name</label>
                        <input type="text" class="form-control" id="user_middleName" v-model="user.middleName">
                    </div>

                    <div class="mb-3 col-md-4 col-12">
                        <label for="user_lastName">Last Name <span style="color:red">*</span></label>
                        <input type="text" class="form-control" id="user_lastName" v-model="user.lastName" required>
                        <div class="invalid-feedback">
                            Please provide a valid Last Name.
                        </div>
                    </div>

                    <div class="mb-3 col-md-6 col-12">
                        <label for="user_phone">Phone <span style="color:red">*</span> </label>
                        <input type="tel" class="form-control" id="user_phone" v-model="user.phone" required>
                        <div class="invalid-feedback">
                            Please provide a valid Phone.
                        </div>
                    </div>

                    <div class="mb-3 col-md-6 col-12">
                        <label for="user_license">License Number <span class="text-muted">(Optional)</span></label>
                        <input type="text" class="form-control" id="user_license" v-model="user.license">
                    </div>
                </div>
            </div>

            <div class="card mt-3">
                <h5 class="card-header">Information</h5>
                <div class="card-body row">
                    <div class="mb-3 col-12">
                        <label for="user_mainAddress">Address <span style="color:red">*</span> </label>
                        <input type="text" class="form-control" id="user_mainAddress" v-model="user.mainAddress" required>
                        <div class="invalid-feedback">
                            Please provide a valid Address.
                        </div>
                    </div>

                    <div class="mb-3 col-12">
                        <input type="text" class="form-control" id="user_address2" placeholder="Apto, house No, ..." v-model="user.address2">
                    </div>

                    <div class="mb-3 col-md-4 col-12">
                        <label for="user_city">City <span style="color:red">*</span> </label>
                        <input type="text" class="form-control" id="user_city" v-model="user.city" required>
                        <div class="invalid-feedback">
                            Please provide a valid City.
                        </div>
                    </div>

                    <div class="mb-3 col-md-4 col-12">
                        <label for="user_state">State <span style="color:red">*</span>  </label>
                        <select id="user_state" class="form-control" v-model="user.state" required>
                            <option :value="state.code" v-for="state in states">{{state.name}}</option>
                        </select>
                        <div class="invalid-feedback">
                            Please provide a valid State.
                        </div>
                    </div>

                    <div class="mb-3 col-md-4 col-12">
                        <label for="address_zip">ZIP Code <span style="color:red">*</span> </label>
                        <input type="text" class="form-control" id="address_zip" v-model="user.zip" required>
                        <div class="invalid-feedback">
                            Please provide a valid Zip Code.
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="col-12 clearfix" style="margin-top:15px">
            <button style="margin-left:5px" class="btn btn-danger float-end" @click.prevent="$emit('onCancel', $event)" v-if="showCancel">Cancel <i class="fa-solid fa-xmark"></i> </button>
            <button type="submit"  class="btn btn-primary float-end" @click="addValidation" > {{ saveLabel }} <i :class="saveIconClass"></i></button>
        </div>

        <spinner ref="userFormSpinner"/>
    </form>
</template>

<script>
import states from '../../../../assets/js/states.js'
import request from '../../../../helpers/request'
import spinner from '../../../utilities/spinner'
import Vuex from 'vuex'
import validateRole from '../../../../mixins/validateRole'

export default {
    name: "userForm",
    mixins: [validateRole],
    components:{
        'spinner':  spinner
    },
    watch:{
        userId: function(val){
            if(val!== null){
                this.getUser();
            }else{
                this.newUser();
            }
        }
    },
    computed:{
		...Vuex.mapState({
            authUser: state => state.auth.user,
            authToken: state => state.auth.token,
        }),
        type(){
            return this.userId?'edit':'create';
        }
    },
    mounted(){
        if(this.userId){
            this.getUser();
        }
    },
    props:{
        userId:{
            type: String,
            default: null
        },
        onlyRegister:{
            type: Boolean,
            default: false
        },
        showCancel:{
            type: Boolean,
            default: true
        },
        saveLabel:{
            type: String,
            default: 'Save'
        },
        saveIconClass:{
            type: String,
            default: 'far fa-save'
        },
        showPassword:{
            type: Boolean,
            default: true
        }
    },
    methods:{
        addValidation(){
            let form = document.getElementById("userForm");
            form.classList.add('was-validated');
        },
        async submitUserForm(){
            try{
                let res;
                this.vpError = false;
                this.emailError = false;
                this.$refs.userFormSpinner.show();
                if(this.user.password != this.verifyPassword){ 
                    this.vpError = true;
                    if(this.cPType == 'password') document.getElementById("verifyPassword1").focus();
                    else if(this.cPType == 'text') document.getElementById("verifyPassword2").focus();
                    throw 'The password Confirmation does not match';
                }
                if(this.userId){
                    if(!this.validateRole(1)){
                        delete this.user._id;
                        delete this.user.role;
                        delete this.user.active;   
                    }
                    if(this.password == null || this.password.length === 0){
                        delete this.password;  
                    }
                    res = await request('POST', '/user/update', this.user, this.authToken);
                }else{
                    res = await request('POST', this.onlyRegister ? '/register' : '/user/create' , this.user, this.authToken);
                }
                this.vpError = false;
                this.emailError = false;
                this.editPassword = true;
                this.verifyPassword = null;
                this.$emit('onSave', {id:res._id?res._id:null, token: res.token?res.token:null, type:this.type});
                this.$refs.userFormSpinner.hide();
            }catch(err){
                if(err !== 'The password Confirmation does not match'){
                    this.emailError = true;
                    document.getElementById("user_email").focus();
                }
                this.$emit('onError', err);
                this.$refs.userFormSpinner.hide();
            }
        },
        async getUser(){
            try{
                this.user = await request('POST', '/user/read', {_id: this.userId}, this.authToken);
                this.$emit('onRead', this.user);
            }catch(err){
                console.log(err);
            }
        },

        newUser(){
            this.vpError = false;
            this.editPassword = true;
            this.verifyPassword = null;
            this.user={
                _id: null,
                email: null,
                password: null,
                role: 2,
                active: true,
                firstName: null,
                lastName: null,
                middleName: null,
                emailAddress: null,
                mainAddress:  null,
                address2: null,
                city: null,
                state: 'FL',
                zip: null,
                phone: null,
                license:null
            }
        },

        switchType(currentType){
            if(currentType === 'password') return 'text';
            else return 'password';
        }
    },
    data(){
        return{
            editPassword : true,
            states: states,
            user:{
                _id: null,
                email: null,
                password: null,
                role: 2,
                active: true,
                firstName: null,
                lastName: null,
                middleName: null,
                emailAddress: null,
                mainAddress:  null,
                address2: null,
                city: null,
                state: 'FL',
                zip: null,
                phone: null,
                license: null
            },
            verifyPassword: null,
            vpError:false,
            emailError: false,
            pType: 'password',
            cPType: 'password',
        }
    }
}
</script>