<template>
    <div class="container">
        <div class="page-margin">
            <button type="button" class="btn btn-primary" @click="$router.push('user/create')" style="float:right;">New <i class="fas fa-plus"></i></button>
            <h2>Users</h2>
            <div class="card" style="margin-top:10px">
                <custom-table source="/user/list" ref="userList" :enableSearch="true">
                    <template #header>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th width="15%"></th>
                    </template>
                    <template #body="{row, index}">
                        <td><router-link class="btn btn-link btn-sm" title="Edit User" :to="`/user/details/${row._id}?name=${row.firstName?row.firstName:''} ${row.middleName?row.middleName:''} ${row.lastName?row.lastName:''}`" > {{ row.firstName?row.firstName:'' }} {{ row.middleName?row.middleName:'' }} {{ row.lastName?row.lastName:'' }} </router-link></td>
                        <td>{{ row.email }} </td>
                        <td>{{ row.role == 1 ? 'Admin':'Student' }} </td>
                        <td> <span :class="[{'text-success': row.active, 'text-danger': !row.active}]"> {{ row.active ? 'Active':'Suspend' }} </span> </td>
                        <td>
                            <router-link class="btn btn-link btn-sm" title="Show User Details" :to="`/user/details/${row._id}?name=${row.firstName?row.firstName:''} ${row.middleName?row.middleName:''} ${row.lastName?row.lastName:''}`"> <i class="fa-solid fa-gear"></i> </router-link>
                            <router-link class="btn btn-link btn-sm" title="Edit User" :to="`/user/read/${row._id}?name=${row.firstName?row.firstName:''} ${row.middleName?row.middleName:''} ${row.lastName?row.lastName:''}`" ><i class="fas fa-edit"></i></router-link>
                            <router-link class="btn btn-link btn-sm" title="show User Courses" :to="`/user/${row._id}/courses?name=${row.firstName?row.firstName:''} ${row.middleName?row.middleName:''} ${row.lastName?row.lastName:''}`" v-if="row.role == 2"><i class="fa-solid fa-chalkboard-user"></i></router-link>
                            <router-link class="btn btn-link btn-sm" title="show User Certificates" :to="`/user/${row._id}/certificates?name=${row.firstName?row.firstName:''} ${row.middleName?row.middleName:''} ${row.lastName?row.lastName:''}`" v-if="row.role == 2"><i class="fa-solid fa-graduation-cap"></i></router-link>
                            <a class="btn btn-link btn-sm" title="Delete User" href="" @click.prevent="deleteUser(row._id)"><i class="fas fa-trash-alt"></i></a>
                        </td>
                    </template>
                </custom-table>
            </div>
        </div>
    </div>
</template>

<script>
import table from '../../../components/utilities/table'
import request from '../../../helpers/request'
import Vuex from 'vuex'

export default {
    name: 'users',
    components:{
        'custom-table': table
    },
    computed:{
            ...Vuex.mapState({
        authToken: state => state.auth.token,
        })
    },
    mounted(){

    },    
    methods:{
        error(err){
            console.log(err);
        },
        async deleteUser(userId){
            try{
                const confirm = window.confirm("Are you sure, of delete this user?")
                if(confirm){
                    await request('POST', '/user/delete', {user: userId}, this.authToken);
                    this.getUsers();
                }
            }catch(err){
                console.log(err);
            }
        },
        getUsers(){
            this.$refs.userList.getRecords();
        }
    },
    data(){
        return {
        }
    }
}
</script>