<template>
    <div class="container">
        <div class="page-margin">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/courses">Courses</a></li>
                  <li class="breadcrumb-item" v-if="course._id" ><a :href="`/course/details/${course._id}`"> {{ course.fullName }}</a></li>
                  <li class="breadcrumb-item active" aria-current="page"> {{ `${course._id?'Settings':'New'}` }}</li>
                </ol>
            </nav>
            <h2>Course <small class="text-muted"> {{ `${course._id?'Settings':'New'}` }}</small> </h2>
            <div class="row">
                <div class="col-12">
                    <alert ref="courseListAlert" :body="alert.message" :type="alert.type"/>
                    <course-form @onSave="onSave" @onError="onError" :courseId="courseId" :enableAccount="true" @onCancel="onCancel" @onRead="course = $event"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import alert from '../../../components/utilities/alert';
import courseForm from "../../../components/app/course/course_form"
export default {
    name: 'course-crud',
    components: {
        'course-form' : courseForm,
        'alert': alert
    },
    methods:{
        openAlert(msg, type, timeOut = 0, back = false){
            this.alert.message = msg;
            this.alert.type = type;
            this.$refs['courseListAlert'].open();
            setTimeout(() => {
                this.$refs['courseListAlert'].close();
                if(back) window.history.back();
            }, timeOut);
        },
        onSave(evt){
            this.openAlert(`course ${evt.type=='create'?'Created':'Updated'} Succesfully`, 'success', 2000, true);
        },
        onError(err){
            this.openAlert(`Error : ${err}`, 'danger', 3500);
        },
        onCancel(evt){
            window.history.back();
        }
    },  
    computed:{
        type(){
            return this.$route.params.type;
        },
        courseId(){
            return this.$route.params.id||null;
        }
    },
    data(){
        return {
            course:{},
            alert:{
                message: null,
                type: null
            }
        }
    }
}


</script>