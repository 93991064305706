<template>
    <div>
        <div class="container">
            <div class="page-margin">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="/courses">Courses</a></li>
                      <li class="breadcrumb-item active" aria-current="page"> Payment </li>
                    </ol>
                </nav>
                <div class="row">
                    <h1>Thank you for your Order!</h1>
                    <h5>Congratulations you have successfully joined a new course, if you have any questions please contact us at 305-401-4516 or send us an email info@sflschoolofrealestate.com</h5>
                </div>
                <a class="btn btn-primary btn-lg" href="/user/courses">Go To My Courses</a>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name: 'payment-sucess'
}
</script>