<template>
    <div v-if="onReady">
        <div style="padding:0px 10px 0px 10px" v-if="exam._id">
            <h4 class="text-muted">{{exam.userFullName}}</h4>
            <template v-if="exam.status == 'active'">
                <div class="exam-timer" v-if="timer">Time Left : {{ secondsToHHmmss(timer) }}</div>
                <div class="row question-content" v-for="(question, qIndex) of exam.questions">
                    <div class="col-12">
                        <h5>{{ question.text }}</h5>
                    </div>
                    <div class="col-12">
                        <div class="form-check" v-for="(answer, aIndex) of question.answers">
                            <input class="form-check-input" type="radio" :id="`question-option-${qIndex}-${aIndex}`" v-model="exam.questions[qIndex]['answerSelectedIndex']" :value="aIndex">
                            <label class="form-check-label" :for="`question-option-${qIndex}-${aIndex}`"> {{ `${choices[aIndex]} &nbsp&nbsp ${answer}` }} </label>
                        </div>
                    </div>
                </div>
                <div>
                    <button class="btn btn-primary" @click="confirmCompleteActiveUserExam"> Complete Exam </button>   
                </div>
            </template>
            <template v-else>
                <div class="row">
                    <div class="col-3"> 
                        <b> Started On</b>
                    </div>
                    <div class="col-9"> 
                        {{ exam.userCreatedDate??exam.createdDate }}
                    </div>

                    <div class="col-3"> 
                        <b> Completed on</b>
                    </div>
                    <div class="col-9"> 
                        {{ exam.userQualificationDate??exam.qualificationDate }}
                    </div>

                    <div class="col-3"> 
                        <b> Grade </b>
                    </div>
                    <div class="col-9">
                        {{ exam.gradePorcent }}
                        <span class="text-success" v-if="exam.approved">
                            <i class="fa-solid fa-check"></i> {{ exam.grade??exam.userGrade }} / {{exam.totalQuestions??exam.userTotalQuestions }} - {{ exam.gradePorcent??exam.userGradePorcent }}%
                        </span>
                        <span class="text-danger" v-else>
                            <i class="fa-solid fa-xmark"></i> {{ exam.grade??exam.userGrade }} / {{exam.totalQuestions??exam.userTotalQuestions }} - {{ exam.gradePorcent??exam.userGradePorcent }}%
                        </span> 
                    </div>

                    <div class="col-3"> 
                        <b> Grade To Pass </b>
                    </div>
                    <div class="col-9"> 
                        {{ exam.gradeToPass??exam.userGradeToPass }}%
                    </div>
                </div>
                
                <div class="row question-content mt-4" v-for="(question, qIndex) of exam.questions">
                    <div class="col-12">
                        <h5>{{ question.text }}</h5>
                    </div>
                    <div class="col-12">
                        <div class="form-check" v-for="(answer, aIndex) of question.answers">
                            <label class="form-check-label" :for="`question-option-${qIndex}-${aIndex}`"> 
                                {{ `${choices[aIndex]} &nbsp&nbsp ${answer}` }}
                                <template v-if="aIndex == question.answerSelectedIndex"> 
                                    <span class="text-success" v-if="question.isCorrect">
                                        <i class="fa-solid fa-check"></i>
                                    </span>
                                    <span class="text-danger" v-else>
                                        <i class="fa-solid fa-xmark"></i>
                                    </span>
                                </template>
                            </label>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="row" v-else>
            <h4 class="text-muted">{{user.fullName}}</h4>
            <button class="btn btn-md btn-primary col-12 col-md-2" @click.prevent="createUserExam()"> Begin Exam </button>
            <div class="col-12 mt-2">Time Limit: {{ content.timeLimit }} Hours </div>
            <div class="col-12">Grade To Pass: {{ content.gradeToPass }} %</div>
            <custom-table 
                source="/course/user/exam/list" 
                class="mt-3" 
                :params="{'contentId': content._id, 'courseId': courseId, 'userId' : user._id, type:'basic' }"
                :showOnlyIfHasRecords="true" 
                ref="courseUserExamList">
                <template #header>
                    <th style="width: 5%;text-align: center;">Attempt</th>
                    <th style="width: 60%">State</th>
                    <th style="width: 15%;text-align: center;">Grade</th>
                    <th style="width: 10%;text-align: center;">Approved</th>
                    <th style="width: 10%;text-align: center;">Review</th>
                </template>
                <template #body="{row, index}">
                    <td> {{ index + 1 }} </td>
                    <td>
                        <span class="text-capitalize"> {{ row.status }} </span> <br>
                        <small class="text-muted">{{ row.finishDate }}</small>
                    </td>
                    <td style="text-align: center;">
                        {{ `${row.grade} / ${row.totalQuestions}`}} - {{ `${parseFloat((row.grade*100) / row.totalQuestions).toFixed(2)} %`  }}
                    </td>
                    <td style="text-align: center;">
                        <span class="text-success" v-if="row.approved">
                            <i class="fa-solid fa-check"></i>
                        </span>
                        <span class="text-danger" v-else>
                            <i class="fa-solid fa-xmark"></i>
                        </span>
                    </td>
                    <td style="text-align: center;">
                        <a class="btn btn-link btn-sm" title="Edit Settings course" :href="`/course/view/${courseId}/${row.contentId}?exam=${row._id}`"> Review <i class="fas fa-eye"></i> </a>
                    </td>
                </template>
            </custom-table>
        </div>
        <alert ref="completeAlert" :body="alert.message" :type="alert.type"/>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="completeExamModal" tabindex="-1" role="dialog" aria-labelledby="completeExamModalTitle" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div :class="[{'modal-dialog': true, 'modal-dialog-centerd': !enableUserForm , 'modal-xl': enableUserForm }]" role="document">
            <div class="modal-content">
                <div class="modal-header" v-if="enableUserForm">
                    <h5 class="modal-title">Account Validation for Certification</h5>
                </div>
                <div class="modal-body">
                    <template v-if="enableUserForm">
                        <user-form :userId="user._id" @onSave="onSave" @onError="onError" @onCancel="onCancel" :showCancel="false" :showPassword="false" saveLabel="Save and Send Certificate"/>
                    </template>
                    <template v-else>
                        <template v-if="completeModalStatus">
                            <h5>Congratulations</h5> 
                            You have successfully completed the course, Now you can download the Certification Course <br>
                            please first validate your personal data and then download your certification.
                        </template>
                        <template v-else>
                            <h5>Failed</h5> 
                            We are sorry, you have not been able to complete the Exam Satisfactorily.
                        </template>
                    </template>
                </div>
                <div class="modal-footer" v-if="!enableUserForm">
                    <template v-if="completeModalStatus">
                        <a href="" @click.prevent="enableUserForm = true" class="btn btn-primary"> Validate <i class="fa-solid fa-user"></i> </a>
                    </template>
                    <template v-else>
                        <a :href="`/course/view/${courseId}/${content.parentId}`" type="button" class="btn btn-secondary"> Close </a>
                        <button type="button" class="btn btn-light" @click.prevent="completeExamModal.hide();"> Continue showing results </button>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <spinner ref="examSpinner"/>
</template>
<script>
import request from '../../../../helpers/request'
import spinner from '../../../utilities/spinner'
import table from '../../../utilities/table'
import alert from '../../../utilities/alert'
import userForm from "../../../app/user/user_form"
import Vuex from 'vuex'
import moment from 'moment'
import {Modal} from 'bootstrap'
export default {
    name: 'content-view-exam',
    props:{
        courseId:{
            type: String,
            default: null
        },
        content:{
            type: Object,
            required: true
        }
    },
    computed:{
        ...Vuex.mapState({
            authToken: state => state.auth.token,
            user: state => state.auth.user, 
            suffix: state => state.auth.suffix
        }),
        examId(){
            return this.$route.query && this.$route.query.exam ? this.$route.query.exam : null;
        }
    },
    watch:{
        exam:{
            handler: function(val){
                this.setCurrentSessionExam(val);
            },
            deep: true
        },
        examId: async function(val){
            if(val !== null) await this.readUserExam(val);
            else this.exam = {}
        }
    },
    async mounted(){
        this.completeExamModal = new Modal(document.getElementById('completeExamModal'));
        if(this.examId !== null){
            this.exam = await this.readUserExam(this.examId);
        }else{
            let currentExam = this.getCurrentSessionExam();
            if(currentExam !== null){
                if(currentExam.contentId === this.content._id && currentExam.courseId === this.courseId){
                    let exam = await this.getActiveUserExam();
                    if(exam && exam._id === currentExam._id && exam.status === 'active'){
                        this.startTimer(moment(exam.userfinishDate??exam.finishDate, "YYYY/MM/DD HH:mm:ss"));
                        this.exam = currentExam;
                    }else{
                        this.deleteCurrentSessionExam();
                    }
                }else{
                    /*alert('please finish the current active exam');
                    window.location.href(`${window.location.protocol}//${window.location.host}/course/view/${currentExam.courseId}/${currentExam.contentId}`);*/
                }
            }else{
                let exam = await this.getActiveUserExam();
                if(exam !== null){
                    this.startTimer(moment(exam.userfinishDate??exam.finishDate, "YYYY/MM/DD HH:mm:ss"));
                    this.exam = exam;
                }
            }
        }
        this.onReady = true;
    },
    methods:{

        getPath(path, addToken = false){
            return `${app.API}/${path}${addToken?`?token=${this.authToken}`:''}`;
        },

        completeExamModalOpen(status){
            this.completeModalStatus = status;
            this.completeExamModal.show();
        },

        async createUserExam(){
            try{
                let confirm = window.confirm(`Your attempt will have a time limit of ${this.content.timeLimit} Hours. When you start, the timer will begin to count down and cannot be paused. You must finish your attempt before it expires. Are you sure you wish to start now?`);
                if(confirm){
                    this.$refs.examSpinner.show();
                    let newExam = await request('POST', '/course/user/exam/create', {'contentId': this.content._id, 'courseId': this.courseId, 'createdDate': moment().format("YYYY/MM/DD HH:mm:ss")}, this.authToken );
                    if(newExam._id){
                        this.startTimer(moment(newExam.userfinishDate??newExam.finishDate, "YYYY/MM/DD HH:mm:ss"));
                        this.exam = newExam;
                    }
                    this.$refs.examSpinner.hide();
                }
            }catch(err){
                this.$refs.examSpinner.hide();
                console.log(err);
            }
        },
        async readUserExam(examId){
            try{
                this.$refs.examSpinner.show();
                let res = await request('POST', '/course/user/exam/read', {'contentId': this.content._id, 'courseId': this.courseId , 'courseUserExamId': examId}, this.authToken );
                if(typeof res.gradePorcent == 'undefined') res['gradePorcent'] = res.grade && res.totalQuestions ? parseFloat((res.grade*100) / res.totalQuestions).toFixed(2): null;
                this.$refs.examSpinner.hide();
                return res;
            }catch(err){
                throw err;
            }
        },
        async getActiveUserExam(){
            try{
                this.$refs.examSpinner.show();
                let res = await request('POST', '/course/user/exam/list', {'contentId': this.content._id, 'courseId': this.courseId, 'all':true, filters:{'status':'active'} }, this.authToken );
                this.$refs.examSpinner.hide();
                return res.records[0]?res.records[0]:null;
            }catch(err){
                console.log(err);
                return null;
            }
        },
        async confirmCompleteActiveUserExam(){
            try{
                let confirm = window.confirm('Are you sure to complete this exam');
                if(confirm){
                    this.deleteCurrentSessionExam();
                    const resExam = await this.completeActiveUserExam();
                    this.exam = resExam;
                    window.scrollTo(0, 0);
                    if(this.exam.examinationType == 'final'){
                        if(this.exam.approved){
                            this.completeExamModalOpen(true);
                        }else{
                            this.completeExamModalOpen(false);
                        }
                    }
                }
            }catch(err){
                console.log(err);
            }
        },
        async completeActiveUserExam(){
            try{
                this.$refs.examSpinner.show();
                let userAnswers = [];
                for(let question of this.exam.questions){
                    userAnswers.push(question.answerSelectedIndex);
                }
                let res = await request('POST', '/course/user/exam/complete', {'courseId': this.courseId , 'courseUserExamId': this.exam._id, 'exam':userAnswers, 'completedDate': moment().format("YYYY/MM/DD HH:mm:ss")}, this.authToken );
                this.$refs.examSpinner.hide();
                return res;
            }catch(err){
                this.$refs.examSpinner.hide();
                throw err;
            }
        },
        setCurrentSessionExam(exam){
            window.localStorage.setItem(this.suffix + 'CurrentSessionExam', JSON.stringify(exam));
        },
        getCurrentSessionExam(){
            return window.localStorage.getItem(this.suffix + 'CurrentSessionExam')? JSON.parse(window.localStorage.getItem(this.suffix + 'CurrentSessionExam')) : null;
        },
        deleteCurrentSessionExam(){
            window.localStorage.removeItem(this.suffix + 'CurrentSessionExam');
        },
        startTimer(end){
            this.interval = setInterval(async() => {
                if(this.timer == null || this.timer > 0){
                    let start = moment();
                    this.timer = end.diff(start, 'seconds');
                }else{
                    clearInterval(this.interval);
                    if(this.timer == 0){
                        this.exam = await this.completeActiveUserExam();
                        this.deleteCurrentSessionExam();
                        alert('the exam time is over, your progress so far has already been sent');
                    }
                    this.timer = null;
                }
            }, 1000);            
        },
        secondsToHHmmss(secs) {
            var minutes = Math.floor(secs / 60);
            secs = secs%60;
            var hours = Math.floor(minutes/60)
            minutes = minutes%60;
            return `${("0"+hours).slice(-2)}:${("0"+minutes).slice(-2)}:${("0"+secs).slice(-2)}`;
        },
        async onSave(){
            try{
                await request('GET', `/certificate/${this.exam.courseUserId}?token=${this.authToken}`, null, null, true);
                this.completeExamModal.hide();
                this.openAlert(`Certificate sended to email registered Succesfully`, 'success', 4500, true);
            }catch(err){
                this.completeExamModal.hide();
                this.onError('Your certificate can not be send please contact with the administrator');
            }
        },
        onError(err){
            this.openAlert(`Error : ${err}`, 'danger', 4000);
        },

        openAlert(msg, type, timeOut = 0, back = false){
            this.alert.message = msg;
            this.alert.type = type;
            this.$refs['completeAlert'].open();
            setTimeout(() => {
                this.$refs['completeAlert'].close();
                if(back) this.$router.push('/user/courses');
            }, timeOut);
        },
        
    },
    components:{
        'spinner':  spinner,
        'alert': alert,
        'custom-table': table,
        'user-form': userForm
    },
    data(){
        return {
            choices: ['A. ', 'B. ', 'C. ', 'D. '],
            exam: {},
            onReady: false,
            timer: null,
            interval: null,
            completeExamModal: null,
            completeModalStatus: null,
            enableUserForm: null,
            alert:{
                message: null,
                type: null
            }
        }
    }
}
</script>

<style>
.question-content{
    margin-bottom: 15px;
    padding: 10px;
    background-color: #efefef;
}
.exam-timer{
    border: 1px solid #d9534f; 
    background-color: #fff;
    position:fixed;
    right: 20px;
    padding: 7px;
}
</style>