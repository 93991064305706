<template>
    <div class="container" style="margin-top: 112px;margin-bottom: 120px;">
        <private-header-menu v-if="authUser._id && authUser._id !== null"/>
        <header-menu v-else/>
        <div class="row">
            <h1>Contact us</h1>
            <h4>(305) 401-4516 - reschool1999@gmail.com</h4>
            <div class="col-12 mt-3">South Florida School of Real Estate has a unique scope of quality, ethical operations in tutoring, training and course preparations for licenses for real estate, mortgage, and insurance community at affordable prices. The company embraces diversity in the global online arena while educating associates, using the latest technology to provide a competitive edge for financial growth for investors, employees and the company while having fun.</div>
        </div>

        <alert ref="contactFormAlert" :body="contactAlert.message" :type="contactAlert.type"/>

        <form class="row mt-5" id="contactForm" @submit.prevent="submitContacForm">

          <div class="mb-3 col-md-6 col-12">
            <label class="form-label">Name</label>
            <input type="text" class="form-control" v-model="name" placeholder="Your Name..." required>
          </div>
          
          <div class="mb-3 col-md-6 col-12">
            <label class="form-label">Email</label>
            <input type="email" class="form-control" v-model="email" placeholder="Your Email..." required>
          </div>

          <div class="mb-3 col-12">
            <label class="form-label">Message</label>
            <textarea class="form-control" rows="10" v-model="message" placeholder="Tell Us in a Few words your requirement" required/>
          </div>

          <div class="col-12 clearfix">
            <button type="submit"  class="btn btn-primary float-end" @click="addValidation" > Send <i class="fas fa-paper-plane"></i></button>
        </div>

        </form>

        <footer class="fixed-bottom container-fluid text-bg-light">
            <p class="text-center">South Florida School of Real Estate© 2023 • All Rights Reserved <span class="text-muted ml-4">v1.0.0</span></p>
        </footer>

        <spinner ref="contactFormSpinner"/>
    </div>
</template>

<script>
import headerMenu from './headerMenu'
import Vuex from 'vuex'
import privateHeaderMenu from '../../components/app/headerMenu'
import alert from '../../components/utilities/alert'
import spinner from '../../components/utilities/spinner'
import request from './../../helpers/request'
export default {
  name: 'contact-us',
  components: {
    'header-menu' : headerMenu,
    'private-header-menu' : privateHeaderMenu,
    'alert': alert,
    'spinner': spinner
  },
  computed:{
    ...Vuex.mapState({
        authUser: state => state.auth.user
    })
  },
  methods:{
    openAlert(msg, type, timeOut = 0, reload){
      this.contactAlert.message = msg;
      this.contactAlert.type = type;
      this.$refs['contactFormAlert'].open();
      setTimeout(() => {
          this.$refs['contactFormAlert'].close();
          if(reload)window.location.reload();
      }, timeOut);
    },
    addValidation(){
      let form = document.getElementById("contactForm");
      form.classList.add('was-validated');
    },
    async submitContacForm(){
      try{
        this.success = false;
        this.$refs.contactFormSpinner.show();
        await request('POST', '/contact', { "name": this.name, 'email': this.email, 'message': this.message });
        this.openAlert(`The E-mail Was sended Succesfully.`, 'success', 2000, true);
        this.$refs.contactFormSpinner.hide();
      }catch(err){
        console.log(err);
        this.openAlert(`The email cannot be sent. Please try again later or contact us by phone.`, 'danger', 2000, false);
        this.$refs.contactFormSpinner.hide();
      }
    }
  },
  data(){
    return {
      name:null,
      email:null,
      message:null,
      contactAlert:{
        message: null,
        type: null
      }
    }
  }
}
</script>