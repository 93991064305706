<template>
    <form id="contentForm" @submit.prevent="submitContentForm" class="row">
        <div class="mb-3 col-12">
            <label for="content_name">Name <span style="color:red">*</span> </label>
            <input type="text" class="form-control" id="content_name" v-model="content.name" autocomplete="new-password" required>
            <div class="invalid-feedback">
                Please provide a Name.
            </div>
        </div>

        <!-- <div class="mb-3 col-12">
            <input class="form-check-input" type="checkbox" v-model="content.active" id="content_active">
            <label class="form-check-label" for="content_active">&nbsp&nbsp Active </label>
        </div> -->
        
        <template v-if="contentType !== 'exam' && content.type !== 'exam'">
            <editor 
                v-model:value="content.body"
                id="content-editor"
            />

            <div class="form-group mt-2">
                <label for="content_embedHTML">Embed HTML</label>
                <textarea class="form-control" id="content_embedHTML" rows="5" v-model="content.embedHTML"></textarea>
            </div>

            <div class="col-12 text-muted" v-if="type=='read' && contentFiles.length > 0">
                <label for="uploadFiles">Uploaded Files</label>
                <file-list id="contentUploadedFileList" :files="contentFiles" :enableViewer="true" :enableDelete="true"  @delete="removeContentFiles"/>
            </div>
    
            <div class="mb-3 col-12">
                <label style="margin-top:10px">Add Files</label>
                <file-input @onInput="setContentFiles" ref="content-input-files"/>
            </div>
        </template>

        <template v-else>
            <div class="form-group">
                <label for="content_description">Description</label>
                <textarea class="form-control" id="content_description" rows="5" v-model="content.description"></textarea>
            </div>

            <div class="mt-2 mb-3 col-12">
                <label for="content_timeLimit">Time Limit <span style="color:red">*</span> </label>
                <input type="time" class="form-control" id="content_timeLimit" min="00:00" max="23:59" v-model="content.timeLimit" autocomplete="new-password" required>
                <div class="invalid-feedback">
                    Please provide a valid time 'HH:mm'.
                </div>
            </div>

            <div class="mb-3 col-12">
                <label for="content_GradeToPass">Grade to Pass <span style="color:red">*</span> </label>
                <input type="number" class="form-control" id="content_GradeToPass" v-model="content.gradeToPass" autocomplete="new-password" required>
                <div class="invalid-feedback">
                    Please provide a valid Grade Number.
                </div>
            </div>
        </template>
        
        <div class="col-12 clearfix" style="margin-top:15px">
            <button class="btn btn-danger float-end" style="margin-left:5px" @click.prevent="$emit('onCancel', $event)">Cancel <i class="fa-solid fa-xmark"></i> </button>
            <button type="submit" class="btn btn-primary float-end" @click="addValidation"> Save <i class="far fa-save"></i></button>
        </div>

        <spinner ref="contentFormSpinner"/>

    </form>
</template>

<script>
import request from '../../../../helpers/request'
import spinner from '../../../utilities/spinner'
import editor from '../../../utilities/editor'
import fileInput from '../../../utilities/fileInput';
import fileList from '../../../utilities/fileList';
import Vuex from 'vuex'

export default {
    name: "contentForm",
    props:{
        courseId:{
            type: String,
            required: true,
        },
        type:{
            type: String,
            required: true,
        },
        contentId:{
            type: String,
            defaullt: null,
        },
        parentId:{
            type: String,
            default: null,
        },
        contentType:{
            type: String,
            default: null,
        }
    },
    computed:{
        ...Vuex.mapState({
            authUser: state => state.auth.user,
            authToken: state => state.auth.token,
        }),
    }, 
    components:{
        'spinner':  spinner,
        'editor': editor,
        'file-input': fileInput,
        'file-list':fileList
    },
    mounted(){
        if(this.courseId && this.type == 'read'){
            this.getContent();
        }
    },
    watch:{
        contentId: function(val){
            if(val!== null && this.type == 'read'){
                this.getContent();
            }else{
                this.newContent(this.parentId);
            }
        }
    },
    methods:{
        addValidation(){
            let form = document.getElementById("contentForm");
            form.classList.add('was-validated');
        },
        removeContentFiles(evt){
            this.content.files.splice(evt.index, 1);
            this.contentFiles.splice(evt.index, 1);
        },
        setContentFiles(files){
            this.newContentFiles = files;
        },
        async submitContentForm(){
            try{
                let res;
                this.$refs.contentFormSpinner.show();
                let formData = new FormData();
                if(this.newContentFiles && this.newContentFiles.length > 0){
                    for(let file of this.newContentFiles){
                        formData.append('files',file);
                    }
                }
                let contentFiles = this.content.files;
                delete this.content.files;
                delete this.content._id;
                delete this.content.parentId;
                if(this.contentType == 'exam' && this.$route.query.examType) this.content['examinationType'] = this.$route.query.examType;
                for(let key in this.content){ formData.append(key,this.content[key]); }
                if(this.type == 'read'){
                    formData.append('contentFiles', JSON.stringify(contentFiles));
                    formData.append('contentId',this.contentId);
                    res = await request('POST', `/content/update?token=${this.authToken}`, formData, null, true);
                }else{
                    if(this.parentId){ formData.append('parentId',this.parentId); }
                    formData.append('courseId',this.courseId);
                    formData.append('type', this.contentType);
                    res = await request('POST', `/content/create?token=${this.authToken}`, formData, null, true);
                }
                this.$emit('onSave', {contentId:res._id, courseId:this.courseId, contentType:this.contentType, type:this.type});
                this.$refs.contentFormSpinner.hide();
            }catch(err){
                console.log(err);
                this.$emit('onError', err);
                this.$refs.contentFormSpinner.hide();
            }
        },
        async getContent(){
            try{
                this.$refs.contentFormSpinner.show();
                this.content = {_id:null};
                this.content = await request('POST', '/content/read', { 'courseId': this.courseId,  'contentId': this.contentId}, this.authToken );
                let contentFiles = JSON.parse(JSON.stringify(this.content.files));
                for(let i = 0; i < contentFiles.length; i++){
                    contentFiles[i] = {'src':`${app.API}/file/${this.courseId}/${contentFiles[i].name}?token=${this.authToken}`, 'name': contentFiles[i].name}
                }
                this.contentFiles = contentFiles;
                this.$emit('onRead', this.content);
                this.$refs.contentFormSpinner.hide();
            }catch(err){
                console.log(err);
                this.$refs.contentFormSpinner.hide();
                //if(this.contentId) this.$router.push(`/course/${this.courseId}`);
            }
        },
        newContent(parentId = null){
            this.content={
                _id: null,
                name: null,
                description: '',
                timeLimit: null,
                body: '',
                embedHTML: '',
                //active: true,
                files:[],
                parentId: parentId,
                gradeToPass: null
            },
            this.contentFiles=[],
            this.newContentFiles=[]
        }
    },
    data(){
        return{
            content:{
                _id: null,
                name: null,
                description: '',
                timeLimit: null,
                body: '',
                embedHTML: '',
                //active: true,
                files:[],
                parentId: null,
                gradeToPass: null
            },
            contentFiles:[],
            newContentFiles:[]
        }
    }
}
</script>