<template>
    <div class="container">
        <div class="page-margin">
            <nav aria-label="breadcrumb" v-if="validateRole(1)">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/users">Users</a></li>
                  <li class="breadcrumb-item"><a :href="`/user/details/${userId}`">{{ userName }}</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Courses</li>
                </ol>
            </nav>
            <button type="button" class="btn btn-primary" @click.prevent="openModalEnrolCourse" style="float:right;" v-if="validateRole(1)" >Enrol Course <i class="fas fa-plus"></i></button>
            <h2>Courses <small class="text-muted"> {{ userName }}</small> </h2>
            <div class="card" style="margin-top:10px">
                <custom-table source="/user/course/list" :params="userId ? { 'userId': userId }: {}" ref="courseList" :enableSearch="true">
                    <template #header>
                        <th style="width: 30%;">Course</th>
                        <th style="width: 50%;">Progress</th>
                        <th style="width: 15%;">Approved</th>
                        <th style="width: 5%;"></th>
                    </template>
                    <template #body="{row, index}">
                        <td @click.prevent="$router.push(`/course/view/${row.course._id}`)" style="cursor:pointer" v-if="row.active">
                            <div class="course-image-list" :style="`background-image: url(${getCourseImage(row.course.image)})`" @click.prevent="$router.push(`/course/view/${row.course._id}`)">
                                <span class="sr-only">Course image</span>
                            </div>
                            <span> {{ row.course.fullName }} </span>
                        </td>
                        <td v-else>
                            <div class="course-image-list" :style="`background-image: url(${getCourseImage(row.course.image)})`">
                                <span class="sr-only">Course image</span>
                            </div>
                            <span> {{ row.course.fullName }} </span>
                        </td>
                        <td v-if="row.active">
                            <template v-for="exam of row.exams"> 
                                <template v-if="exam.active">
                                    <a  v-if="exam.enable"
                                        style="text-decoration:none !important;"
                                        :href="`/course/view/${row.course._id}/${exam.contentId}`" 
                                    >   
                                        {{ exam.name }} 
                                    </a>
                                    <a  v-else
                                        style="text-decoration:none !important;"
                                        class="text-muted"
                                    >   
                                        {{ exam.name }} &nbsp&nbsp<i class="fa-solid fa-lock"></i> 
                                    </a>
                                </template>
                                <template v-else>
                                    <a
                                        :class="[{'text-success': exam.approved, 'text-danger': !exam.approved }]"
                                        :href="`/course/view/${row.course._id}/${exam.contentId}?exam=${exam.examId}`"
                                        style="text-decoration:none !important;"
                                    > 
                                        {{ exam.name }} &nbsp&nbsp&nbsp&nbsp Grade : {{ exam.grade }} / {{ exam.gradeToPass }} 
                                        <i class="fa-solid fa-check" v-show="exam.approved"></i>
                                        <i class="fa-solid fa-xmark" v-show="!exam.approved"></i>  
                                    </a>
                                </template>
                                <br>
                            </template>
                        </td>
                        <td v-else>
                            <template v-for="exam of row.exams">
                                <span
                                    :class="[{'text-success': exam.approved, 'text-danger': !exam.approved }]"
                                >
                                    {{ exam.name }} &nbsp&nbsp&nbsp&nbsp Grade : {{ exam.grade }} / {{ exam.gradeToPass }} 
                                    <i class="fa-solid fa-check" v-show="exam.approved"></i>
                                    <i class="fa-solid fa-xmark" v-show="!exam.approved"></i>  
                                </span>
                                <br> 
                            </template> 
                        </td>
                        <td>
                            <template v-if="!row.active"> 
                                <span class="text-success" v-if="row.approved"> <i class="fa-solid fa-check"></i> </span>
                                <span class="text-danger"  v-else> <i class="fa-solid fa-xmark"></i> </span>
                            </template>
                            <template  v-else>
                                <span class="text-muted"> In progress.. </span>
                            </template>
                        </td>
                        <td>
                            <a
                                v-if="row.approved" 
                                :href="getPath(`certificate/${row._id}`, true)" 
                                class="btn btn-link" 
                                title="Download certificate" 
                                download>  
                                 <i class="fa fa-download" aria-hidden="true"></i> 
                            </a>
                        </td>
                    </template>
                </custom-table>
            </div>
        </div>


        <!-- enrolCourseModal -->
        <div class="modal fade" id="enrolCourseModal" tabindex="-1" aria-labelledby="enrolCourseModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="enrolCourseModalLabel">Enrol <small class="text-muted">{{ userName }}</small> On</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <custom-selector v-model:value="courseSelected" source="/course/list" id="enrol-course-selector" label="Select Course" :filters="{'active':true}">
                            <template #value="{record}">  
                                <span v-if="record"> 
                                    {{ `${record.fullName}` }} <small class="text-muted">{{ record.shortName }}</small>
                                </span>
                            </template>
                            <template #option="{record}">
                                {{ `${record.fullName}` }} <small class="text-muted">{{ record.shortName }}</small>
                            </template>
                        </custom-selector>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click.prevent="enrollUser()" :disabled="courseSelected == null">Save <i class="far fa-save"></i></button>
                        <button type="button" class="btn btn-danger" @click.prevent="closeModalEnrolCourse">Cancel <i class="fa-solid fa-xmark"></i> </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import validateRole from '../../../mixins/validateRole'
import table from '../../../components/utilities/table'
import selector from '../../../components/utilities/selector'
import request from '../../../helpers/request'
import {Modal} from 'bootstrap'
import Vuex from 'vuex'

export default {
    name: 'user-course-list',
    mixins: [validateRole],
    components:{
        'custom-table': table,
        'custom-selector': selector
    },
    computed:{
        ...Vuex.mapState({
            authToken: state => state.auth.token,
        }),
        userId(){
            return this.$route.params.userId ? this.$route.params.userId : null;
        },
        userName(){
            return this.$route.query.name ? this.$route.query.name : 'User';
        }
    },
    mounted(){
        this.enrolCourseModal = new Modal(document.getElementById('enrolCourseModal'));
    },    
    methods:{
        error(err){
            console.log(err);
        },
        getCourses(){
            this.$refs.courseList.getRecords();
        },
        getCourseImage(path){
            return `${app.API}/${path}`;
        },
        getPath(path, addToken = false){
            return `${app.API}/${path}${addToken?`?token=${this.authToken}`:''}`;
        },
        openModalEnrolCourse(){
            this.courseSelected =  null;
            this.enrolCourseModal.show();
        },
        closeModalEnrolCourse(){
            this.enrolCourseModal.hide();
        },
        async enrollUser(){
            try{
                const confirm = window.confirm("Are you sure, of enroll in this Course?")
                if(confirm){
                    await request('POST', '/course/user/create', {courseId: `${this.courseSelected._id}`, userId: this.userId }, this.authToken);
                    this.getCourses();
                    this.closeModalEnrolCourse();
                }
            }catch(err){
                console.log(err);
            }
        },
    },
    data(){
        return {
            courseSelected: null,
            enrolCourseModal: null,
        }
    }
}
</script>

<style>
.course-image-list{
    height: 5rem; 
    width: 20rem;
    background-position: center;
    background-size: cover;
}

</style>