import store from '../store'
import { createRouter, createWebHistory } from 'vue-router'
//import pages/views
import main from '../views/private/main.vue'
import login from '../views/public/login.vue'
import pHome from '../views/public/home.vue'
import pCourseDetails from '../views/public/courseDetails.vue'
import aboutUs from '../views/public/aboutUs.vue'
import contactUs from '../views/public/contactUs'
import ivania from '../views/public/ivania.vue'
import register from '../views/public/register.vue'
import recovery from '../views/public/recovery.vue'

import userCRUD from '../views/private/user/crud.vue'
import userList from '../views/private/user/list.vue'
import decodeJWT from '../helpers/decodeJWT'
import userSettings from '../views/private/user/settings.vue'
import userDetails from '../views/private/user/details.vue'

import courseDetails from '../views/private/course/details.vue'
import courseView from '../views/private/course/view.vue'
import courseList from '../views/private/course/list.vue'
import courseCRUD from '../views/private/course/crud.vue'
import courseUserList from '../views/private/course/userList.vue'
import courseExamList from '../views/private/course/examList.vue'

import contentCRUD from '../views/private/content/crud.vue'
import questionCRUD from '../views/private/question/crud.vue'

import paymentFail from '../views/private/payment/fail.vue'
import paymentSuccess from '../views/private/payment/success.vue'

import quickPaymentFail from '../views/public/quickPayment/fail.vue'
import quickPaymentSuccess from '../views/public/quickPayment/success.vue'

import userCourseList from '../views/private/user/courseList.vue'
import userCertificateList from '../views/private/user/certificateList.vue'
import home from '../views/private/home/index.vue'

const routes = [
  {
    path: '/',
    name: 'pHome',
    component: pHome,
    meta: { middlewarelogin: true },
  },
  {
    name: 'pCourse-details',
    path: '/online-course/:id',
    component: pCourseDetails
  },
  {
    name: 'quick-payment-fail',
    path: '/quick-payment/fail',
    component: quickPaymentFail
  },
  {
    name: 'quick-payment-success',
    path: '/quick-payment/success',
    component: quickPaymentSuccess
  },
  {
    name: 'about-us',
    path: '/about-us',
    component: aboutUs
  },
  {
    name: 'contact-us',
    path: '/contact-us',
    component: contactUs
  },
  {
    name: 'ivania-alvarado',
    path: '/ivania-alvarado',
    component: ivania
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: { middlewarelogin: true },
  },
  {
    path: '/recovery',
    name: 'recovery',
    component: recovery,
    meta: { middlewarelogin: true },
  },
  {
    path: '/register',
    name: 'register',
    component: register,
    meta: { middlewarelogin: true },
  },
  {
    path: '/',
    component: main,
    meta: { middlewareAuth: true },
    children: [
      {
        name: 'home',
        path: '/home',
        component: home,
      },
      {
        name: 'user-list',
        path: '/users',
        component: userList,
        meta: { middlewareValidateRoleAdmin : true }
      },
      {
        name: 'user-crud',
        path: '/user/:type/:id?',
        component: userCRUD,
        meta: { middlewareType: true, middlewareValidateRoleAdmin : true },
      },
      {
        name: 'user-settings',
        path: '/user/settings',
        component: userSettings
      },
      {
        name: 'user-details',
        path: '/user/details/:id',
        component: userDetails,
        meta: { middlewareValidateRoleAdmin : true }
      },
      {
        name: 'course-list',
        path: '/courses',
        component: courseList
      },
      {
        name: 'payment-fail',
        path: '/payment/fail',
        component: paymentFail
      },
      {
        name: 'payment-success',
        path: '/payment/success',
        component: paymentSuccess
      },
      {
        name: 'course-details',
        path: '/course/details/:id',
        component: courseDetails
      },
      {
        name: 'course-crud',
        path: '/course/:type/:id?',
        component: courseCRUD,
        meta: { middlewareType: true, middlewareValidateRoleAdmin : true },
      },
      {
        name: 'course-view',
        path: '/course/view/:id/:_contentId?',
        component: courseView
      },
      {
        name: 'content-crud',
        path: '/course/:courseId/content/:type/:contentId?',
        component: contentCRUD,
        meta: { middlewareValidateRoleAdmin : true },
      },
      {
        name: 'question-crud',
        path: '/question/course/:courseId/content/:contentId?/:type/:id?',
        component: questionCRUD,
        meta: { middlewareValidateRoleAdmin : true },
      },
      {
        name: 'course-user-list',
        path: '/course/users/:courseId',
        component: courseUserList,
        meta: { middlewareValidateRoleAdmin : true },
      },
      {
        name: 'course-exams',
        path: '/course/exams/:courseId',
        component: courseExamList,
        meta: { middlewareValidateRoleAdmin : true },
      },
      {
        name: 'user-course-list-admin',
        path: '/user/:userId/courses',
        component: userCourseList,
        meta: { middlewareValidateRoleAdmin : true },
      },
      {
        name: 'user-course-list-student',
        path: '/user/courses',
        component: userCourseList,
      },
      {
        name: 'user-certificate-list-admin',
        path: '/user/:userId/certificates',
        component: userCertificateList,
        meta: { middlewareValidateRoleAdmin : true },
      },
      {
        name: 'user-certificate-list',
        path: '/user/certificates',
        component: userCertificateList,
      },
      {
        path: '/',
        component: home
      },
      {
        path: '/:pathMatch(.*)*',
        component: home
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async(to, from, next) => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  if (to.matched.some(record => record.meta.middlewareAuth)) {
      if (!store.state.auth.token) {
        next({ path: '/' });
      }
  } else if (to.matched.some(record => record.meta.middlewarelogin)) {
      if (store.state.auth.user && store.state.auth.token) {
        next({ path: '/home' });
      }
  }

  if(to.matched.some(record => record.meta.middlewareValidateRoleAdmin)){
    let data = decodeJWT(store.state.auth.token);
    if (data.role !== 1) {
      next({ path: '/home' });
    }
  }
  
  if(to.matched.some(record => record.meta.middlewareType)){
    if(to.params.type == 'create'){
      next();
    }else if(to.params.type == 'read'){
      if(to.params.id && to.params.id !== "" ){
        next();
      }
    }
    next({ path: '/home' });
  }
  
  next();
})

export default router
