<template>
    <div class="container" style="margin-top: 112px;margin-bottom: 120px;">
        <private-header-menu v-if="authUser._id && authUser._id !== null"/>
        <header-menu v-else/>
        <div class="row">
            <div class="mb-2" style="display: flex;align-items: center;justify-content: center;">
              <div style="text-align: center;">
                <img src="./.././../assets/images/ivania-alvarado.jpeg" style="height: 400px;" class="align-self-start mr-3 rounded-circle float-left" alt="...">
                <h1 class="mt-2">Ivania Alvarado</h1>
                <p>
                  (305) 401-4516 - reschool1999@gmail.com <br> 
                  <a href="https://www.linkedin.com/in/ivania-alvarado-07577825/"> https://www.linkedin.com/in/ivania-alvarado-07577825</a> <br>
                  Driven professional with 25+ years of real estate broker and manger experience
                </p>
              </div>
            </div>
            <hr>
            <h4 class="mt-3">SUMMARY OF QUALIFICATIONS</h4>
            <div class="row">
              <div class="col-12">
                Multitask, goal-oriented, real estate broker, and real estate instructor with a vast experience working with clients, agents, and staff. Extensive experience managing real estate, mortgage, and title companies as well as school of real estate. Helping sales associates to achieve their goals. Thru the years, programs were made for sales agents that includes mentorships, telemarketing, knocking doors, and pre-qualifying buyers.
                <ul>
                  <li>Property Sales</li>
                  <li>Sales Training and Leadership</li>
                  <li>Prospecting</li>
                  <li>Farming</li>
                  <li>Operational Management</li>
                  <li>Recruiting</li>
                  <li>Rent/Leases</li>
                  <li>Coaching/Mentoring/Consulting Agents</li>
                  <li>Escrow/Titles</li>
                  <li>Legal/Dispute Resolution</li>
                  <li>Negotiation</li>
                  <li>Strategy Decision Making</li>
                  <li>Finance Analysis</li>
                  <li>Office and File Compliance</li>
                  <li>Network event coordinator</li>
                  <li>Prequalifying Buyers</li>
                  <li>Customer Service</li>
                  <li>Team Meeting</li>
                  <li>Telemarketing</li>
                  <li>Door Knocking</li>
                  <li>Managing, Payroll</li>
                  <li>Property management</li>
                  <li>Marketing</li>
                  <li>FAR/BAR contracts</li>
                  <li>Commissioning</li>
                </ul>
              </div>
            </div>
            
            <hr>

            <h4 class="mt-3">EDUCATION</h4>
            <div class="row">
              <div class="col-12">
                <strong>Florida Atlantic University </strong>, Davie, Florida August 2023 
                <p>Master of Taxation</p>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <strong>Florida Atlantic University </strong>, Miami, Florida July 2021
                <p>Bachelor of Supervision and Management- Accounting Concentration</p>
              </div>
            </div>

            <hr>
    
            <h4 class="mt-3">PROFESSIONAL EXPERIENCE</h4>
            <div class="row">

              <div class="col-12">
                <strong>Miami Dade College</strong>
                <p>Adjunct Professor, January 2023 – Present</p>
                <ul>
                  <li>Teach, prepare, and review exams for several classes (Real estate, QuickBooks, Tax1 & 2, Finance, Marketing, Sales, Property Management, and more.</li>
                </ul>
              </div>

              <div class="col-12">
                <strong>Miami Dade College and the IRS</strong>
                <p>VITA Preparer, February-April 2022, February -April 2023, & February-April 2024 </p>
                <ul>
                  <li>Prepare and review income tax returns, and file amendments.</li>
                </ul>
              </div>

              <div class="col-12">
                <strong>Net Realty Services Miami, Florida</strong>
                <p>Owner, Broker November 2017 – Present</p>
                <ul>
                  <li>Administrating the real estate company and complying: in charge of reviewing all agents’ transactions and making sure all contracts are correct and require disclosures and addendums in it.</li>
                  <li>Answer agents’ general questions, conduct at least 2 productivity trainings per week, setting goals, and productivity of agents based on their goals.</li>
                  <li>Recruiting 12 active agents and referring agents to other brokerage companies.</li>
                  <li>Accounting, payroll, escrows, disbursement of all transactions, and more.</li>
                  <li>Personally list 2 to 3 listings per month.</li>
                </ul>
              </div>

              <div class="col-12">
                <strong>South Florida School of Real Estate Miami, Florida</strong>
                <p>Owner-Instructor February 1999 – Present</p>
                <ul>
                  <li>Managing the school and several instructors to maintain all the courses.</li>
                  <li>Teaching multiples courses such as: sales associate, broker pre-license, continuing education, sales and broker post license, reactivation course. Also teaching a division for Florida mortgage loan originator and Florida insurance.</li>
                  <li>Sales training to achieve personal goals for students.</li>
                  <li>Coaching, mentoring, and training sales agents in more than 30 different classes</li>
                  <li>Thousands of students attended the school within 21 years and many new students continue to come.</li>
                </ul>
              </div>

              <div class="col-12">
                <strong>One Stop Realty Miami, Florida</strong>
                <p>Broker Associate February 2015 – November 2016</p>
                <ul>
                  <li>Worked with sellers and buyers to sell or buy their property.</li>
                  <li>Coordinating the whole transaction from beginning to close (Listing or Purchase).</li>
                  <li>Conferring with lenders, Title companies, and report inspections to ensure that all conditions were met before closing.</li>
                  <li>Prospecting using systems like: farming, cold calling/telemarketing, door hangers/door knocking, open house, etc.</li>
                </ul>
              </div>

              <div class="col-12">
                <strong>Keller Williams Miami, Florida</strong>
                <p>Broker Associate July 2014 – February 2015</p>
                <ul>
                  <li>Coordinating appointments to show homes to prospective buyers.</li>
                  <li>Farming Kendall area to prospect seller and do listing presentation.</li>
                </ul>
              </div>

              <div class="col-12">
                <strong>South Florida Realty Miami, Florida</strong>
                <p>Real Estate Broker October 1996 – February 2012</p>
                <ul>
                  <li>Principal broker in charge of 140-160 active agents, office staff, and supervise all open and closed transactions.</li>
                  <li>In charge of recruiting new agents on a daily basis, help them set their goals to achieve the sales desire, and coordinate and conduct regular weekly company trainings.</li>
                  <li>Escrow account management for HUD home properties and more.</li>
                  <li>Closed over 250 transactions per year.</li>
                </ul>
              </div>

              <div class="col-12">
                <strong>American Mortgage Lending Miami, Florida</strong>
                <p>Vice-president November 2001 – July 2010</p>
                <ul>
                  <li>Evaluate mortgage options to help clients obtain financing.</li>
                  <li>Trained around 150 loan originators on how to prequalify a buyer and find the right loan program.</li>
                </ul>
              </div>

              <div class="col-12">
                <strong>Imperial Title Miami, Florida</strong>
                <p>Vice-president August 2002 – July 2004</p>
                <ul>
                  <li>Account representative in charge of promoting and getting new accounts such as mortgage and real estate companies.</li>
                  <li>Transaction coordinator between the realtors/brokers and the title company staff.</li>
                  <li>Send wire funds to banks, clients, mortgage and real estate companies, lenders, agents, court, liens, recordings, etc.</li>
                  <li>Supervised 40 to 70 closed transaction per month.</li>
                </ul>
              </div>

              <div class="col-12">
                <strong>Imperial Insurance Miami, Florida</strong>
                <p>Vice-president June 2000 – March 2002</p>
                <ul>
                  <li>Visited title companies to get new accounts.</li>
                  <li>Hired and interviewed the personnel for the insurance agency.</li>
                </ul>
              </div>

              <div class="col-12">
                <strong>South Florida Lending Miami, Florida</strong>
                <p>Principle Broker April 1997 – August 2003</p>
                <ul>
                  <li>Interview and hire processors, clerical staff, and loan originators.</li>
                  <li>Assisted loan originators with trainings and questions to learn all mortgage programs.</li>
                  <li>Helped with filing the 1003 applications when the loan originator is new or has questions and regular trainings.</li>
                </ul>
              </div>

              <div class="col-12">
                <strong>Bankers Choice</strong>
                <p>Loan Originator, November 1997 – April 2002</p>
                <ul>
                  <li>Evaluate mortgage options to help clients obtain financing.</li>
                  <li>Trained loan originators on how to pre-qualify a buyer and find the right loan program.</li>
                </ul>
              </div>

              <div class="col-12">
                <strong>I&amp;F Insurance Miami, Florida</strong>
                <p>Vice-president-Account Rep December 2000 – December 2001</p>
                <ul>
                  <li>Go to events to find new underwriters.</li>
                  <li>Visited title companies to get new accounts and get together at networking events.</li>
                  <li>Taught the staff to comply with the office and procedures.</li>
                </ul>
              </div>
            </div>

            <hr>
          
            <h4 class="mt-3">LICENSES</h4>
            <div class="row">
              <div class="col-12">
                <ul>
                  <li>License Real Estate Broker, Florida</li>
                  <li>License Real Estate Instructor, Florida</li>
                  <li>Certificate in General Business from Miami Dade College</li>
                  <li>Associate’s in Arts from Miami Dade College</li>
                  <li>PTIN: P02487047</li>
                </ul>
              </div>
            </div>

            <hr>

            <h4 class="mt-3">SKILLS</h4>

            <div class="row">
              <div class="col-12">
                <ul>
                  <li><b>Language:</b> English and Spanish.</li>
                  <li><b>Software:</b> Microsoft Excel, Microsoft Word, PowerPoint.</li>
                  <li>CMA, Cloud CMA</li>
                  <li>iMAPP, Realist</li>
                  <li>Matrix, Rapattoni</li>
                  <li>PROXIO</li>
                  <li>Form Simplicity, Far Bar Contracts</li>
                  <li>RPR, Remine</li>
                  <li>Mentorship, Tutoring, Consulting, Property Management, Marketing, Operational Management, Bookkeeping, Income
                    Tax returns preparer, CCH, Bloomberg BNA, Nexis Uni, Westlaw, Checkpoint Edge, Professor, Author.</li>
                  <li>DotLoop, and more</li>
                </ul>
              </div>
            </div>

            
        </div>


        <footer class="fixed-bottom container-fluid text-bg-light">
            <p class="text-center">South Florida School of Real Estate© 2023 • All Rights Reserved <span class="text-muted ml-4">v1.0.0</span></p>
        </footer>
    </div>
</template>

<script>
import headerMenu from './headerMenu'
import Vuex from 'vuex'
import privateHeaderMenu from '../../components/app/headerMenu'
export default {
  name: 'about-us',
  components: {
    'header-menu' : headerMenu,
    'private-header-menu' : privateHeaderMenu,
  },
  computed:{
    ...Vuex.mapState({
        authUser: state => state.auth.user
    })
  }
}
</script>