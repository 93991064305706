<template>
    <div    style="margin-top: 2px;background-color: white; padding:8px;"
            :style="[`${!content.parentId?'border-top: 3px solid #5274ff;':''}`,
            `${enableEdit?'position:relative':''}`
        ]"
        @mouseover="onMouseEvent(content, true)" 
        @mouseleave="onMouseEvent(content, false)"
        :class="[{'edit-hover':enableEdit && content.isHover}]" v-for="content of contents">

            
        <div style="position: absolute;top: 0.1rem;right: 0px;z-index:10" v-if="enableEdit">
            <a href="#" class="btn btn-outline-dark btn-sm" @click.prevent="onAdd(content._id)"><i class="fa fa-plus"></i> Add new Content</a>
            <a href="#" class="btn btn-outline-dark btn-sm" style="margin-left:4px" @click.prevent="onEdit(content._id, content.type)"><i class="fa fa-edit"></i></a>
            <a href="#" class="btn btn-outline-dark btn-sm" style="margin-left:4px" @click.prevent="deleteContent(content._id)"><i class="fa fa-trash"></i></a>
        </div>

        <div v-if="content.parentId == null">
            <router-link :to="`/course/view/${courseId}/${content._id}`"><h4 style="color: #0d6efd;text-decoration: underline;cursor:pointer">{{ content.name }}</h4></router-link>
        </div>

        <template v-if="content.body && content.body.length > 0">
            <h3 v-if="type=='child'">{{content.name}}</h3>
            <div v-html="content.body"/>
        </template>

        <template v-if="content.embedHTML && content.embedHTML.length > 0">
            <div style="display: flex;align-items: center;justify-content: center;">
                <div v-html="content.embedHTML"/>
            </div>
        </template>

        <template v-if="type=='parent'">
            <content-view-files :courseId="courseId" :contentId="content._id" :title="content.name" :files="content.files"/>
            <content-view-exam :content="content" :courseId="courseId" v-if="content.type == 'exam'"/>
        </template>
        <template v-else>
            <div class="card" style="cursor:pointer;" @click.prevent="$router.push(`/course/view/${courseId}/${content._id}`)" v-if="content.type == 'file'">
                <div class="card-body d-flex">
                    <a href="#" class="btn btn-primary btn-lg"><i class="fa-solid fa-file-lines"></i></a>
                    <h5 class="card-title" style="color: #0d6efd;flex:1; margin-left: 10px;">{{ content.name }}</h5>
                </div>
            </div>

            <template v-else-if="content.type == 'exam'">
                <div class="card" style="cursor:pointer;" @click.prevent="$router.push(`/course/view/${courseId}/${content._id}`)" v-if="content.examinationType !== 'final' || content.enable">
                    <div class="card-body d-flex">
                        <a href="#" class="btn btn-danger btn-lg"><i class="fa-solid fa-file-lines"></i></a>
                        <h5 class="card-title" style="color: #0d6efd;flex:1; margin-left: 10px;">{{ content.name }}</h5>
                    </div>
                </div>

                <div class="card" v-else>
                    <div class="card-body d-flex">
                        <a class="btn btn-secondary btn-lg"><i class="fa-solid fa-file-lines"></i></a>
                        <h5 class="card-title text-muted" style="flex:1; margin-left: 10px;">{{ content.name }} &nbsp&nbsp<i class="fa-solid fa-lock"></i> </h5>
                    </div>
                </div>

            </template>
        </template>

        <template v-if="content.content && content.content.length > 0">
            <content-view :enableEdit="enableEdit" :courseId="courseId" :contents="content.content" type="child" @onNewContent="onAdd($event)"/>
        </template>
        
    </div>
  </template>
  
<script>
import contentViewFiles from './files.vue'
import contentViewExam from './exam.vue'
import contentView from './index.vue'
import request from '../../../../helpers/request'
import Vuex from 'vuex'

export default {
    name: 'content-view',
    computed:{
        ...Vuex.mapState({
            authToken: state => state.auth.token,
        }),
    }, 
    components:{
        'content-view' : contentView,
        'content-view-files' : contentViewFiles,
        'content-view-exam': contentViewExam
    },
    props:{
        contents:{
            type: Array,
            required: true
        },
        courseId:{
            type: String,
            required: true
        },
        enableEdit:{
            type: Boolean,
            default: true
        },
        type:{
            type: String,
            default: 'parent'
        } 
    },
    methods:{
        onMouseEvent(content, hover){
            content['isHover'] = hover;
        },
        onAdd(contentId){
            this.$emit('onNewContent', contentId);
        },
        onEdit(contentId, contentType){
            this.$router.push(`/course/${this.courseId}/content/read/${contentId}?type=${contentType}`);
        },
        async deleteContent(contentId){
            try{
                let confirm = window.confirm('Are you sure to delete this content?')
                if(confirm){
                    await request('POST', '/content/delete', {contentId: contentId}, this.authToken);
                    window.location.reload();
                }
            }catch(err){
                console.log(err);
            }
        }
    },
    data(){
        return {
            isHover: false,
        }
    }
} 
</script>

<style>
.edit-hover{
    background: #f5f7ff !important;
}
</style>