import decodeJWT from '../../helpers/decodeJWT';
import store from '../../store'

export default {
    methods:{
        validateRole(role){
            try{
                let data = decodeJWT(store.state.auth.token);
                return data.role === role;
            }catch(err){
                return false;
            }   
        }
    }
}