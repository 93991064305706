<template>
    <div class="container" style="margin-top: 112px;margin-bottom: 120px;">
        <private-header-menu v-if="authUser._id && authUser._id !== null"/>
        <header-menu v-else/>
        <div class="row col-12">
            <h1>About us</h1>
            <p>We have offices in Dade and Broward county, please call to find the office nearest you. Please complete this form to send questions or comments.</p>
            
            <div class="text-center">
                <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/ejulGadKdJk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
            </div>
        
            <h2>Instructors</h2>
            <div class="media">
              <img src="./.././../assets/images/ivania-alvarado.jpeg" style="width:150px" class="align-self-start mr-3 rounded-circle" alt="...">
              <div class="media-body">
                <h5 class="mt-2">Ivania Alvarado</h5>
                <p>(305) 401-4516 - reschool1999@gmail.com <br> <a href="https://www.linkedin.com/in/ivania-alvarado-07577825/"> https://www.linkedin.com/in/ivania-alvarado-07577825</a></p>
                Real estate instructor with a vast experience working with clients, agents, and staff. Extensive experience managing real estate, mortgage, and title companies as well as school of real estate. Helping sales associates to achieve their goals.
                <p><router-link to="/ivania-alvarado">Read more</router-link></p>
              </div>
            </div>
        </div>


        <footer class="fixed-bottom container-fluid text-bg-light">
            <p class="text-center">South Florida School of Real Estate© 2023 • All Rights Reserved <span class="text-muted ml-4">v1.0.0</span></p>
        </footer>
    </div>
</template>

<script>
import headerMenu from './headerMenu'
import Vuex from 'vuex'
import privateHeaderMenu from '../../components/app/headerMenu'
export default {
  name: 'about-us',
  components: {
    'header-menu' : headerMenu,
    'private-header-menu' : privateHeaderMenu,
  },
  computed:{
    ...Vuex.mapState({
        authUser: state => state.auth.user
    })
  }
}
</script>