<template>
  <div style="padding: 0.5rem 0.5rem;" :class="[{'bg-primary':link._id == contentId}]" v-for="(link, index) of links">
    <template v-if="link.content.length==0">
      <template v-if="link.type == 'exam' && link.examinationType == 'final'">
        <router-link style="color:#fff;font-size:.91rem;text-decoration: none;" :to="`/course/view/${courseId}/${link._id}`" v-if="link.enable">{{ truncate(link.name, 25) }}</router-link>
        <span style="color:#fff;font-size:.91rem" v-if="!link.enable">{{ truncate(link.name, 25) }} &nbsp&nbsp<i class="fa-solid fa-lock"></i> </span>
      </template>
      <template v-else>
        <router-link style="color:#fff;font-size:.91rem;text-decoration: none;" :to="`/course/view/${courseId}/${link._id}`">{{ truncate(link.name, 25) }}</router-link>
      </template>
    </template>
    <template v-else>
      <a class="dropdown-toggle" 
        style="color:#fff;font-weight: 700;text-decoration: none;"  
        data-bs-toggle="collapse" 
        :href="`#collapse${link._id}`" 
        @click.prevent="$router.push(`/course/view/${courseId}/${link._id}`)" role="button" aria-expanded="false" :aria-controls="`collapse${link._id}`">
        {{ truncate(link.name, 25) }}
      </a>
      <div class="collapse" :id="`collapse${link._id}`">
        <div style="display:flex; flex-direction: column;" v-for="child of link.content">
          <nav-link :courseId="courseId" :links="[child]"></nav-link>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import navLinks from './navLinks.vue'
export default {
  name: 'nav-link',
  components:{
    'nav-link': navLinks
  },
  props:{
    links:{
      type: Array,
      required: true
    },
    courseId:{
      type: String,
      required: true
    }
  },
  computed:{
    contentId(){
      return this.$route.params._contentId?this.$route.params._contentId:null;
    }
  },
  methods:{
    to(link){
      this.router.push(link);
    },
    truncate(str, n){
      return (str.length > n) ? str.slice(0, n-1) + '...' : str;
    }
  }
} 
</script>
