<template>
    <form id="questionForm" @submit.prevent="saveQuestion" class="row">
        <div class="mb-3 col-12">
            <label for="question_name">Name <span style="color:red">*</span> </label>
            <input type="text" class="form-control" id="question_name" v-model="question.name" required>
        </div>

        <div class="mb-3 col-12">
            <label for="question_text">Text <span style="color:red">*</span> </label>
            <textarea class="form-control" id="question_text" rows="5" v-model="question.text" required></textarea>
        </div>
        
        <div class="col-12">
            <h5> Answers </h5>
            <div class="card mb-3" v-for="(answer, index) in question.answers">
                <div class="card-body row">
                    <div class="col-12">
                        <label class="mb-2" :for="`question_answer-${index}`">Choice {{ choices[index] }}</label>
                        <div class="d-flex">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" :value="index" v-model="question.correctAnswerIndex" name="question-options" :id="`question_answer-${index}`" required>
                            </div>
                            <input type="text" class="form-control" v-model="question.answers[index]" required>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-12 clearfix" style="margin-top:15px">
            <button class="btn btn-danger float-end" style="margin-left:5px" @click.prevent="cancelQuestion">Cancel <i class="fa-solid fa-xmark"></i> </button>
            <button type="submit" class="btn btn-primary float-end" @click="addValidation" > Save <i class="far fa-save"></i></button>
        </div>
        <spinner ref="questionFormSpinner"/>
    </form>
</template>

<script>
import request from '../../../../helpers/request'
import spinner from '../../../utilities/spinner'
import Vuex from 'vuex'

export default {
    name: "question-form",
    props:{
        questionId:{
            type: String,
            default: null
        },
        courseId:{
            type: String,
            required: true
        },
        contentId:{
            type: String,
            required: true
        }
    },
    components:{
        'spinner':  spinner
    },
    watch:{
        questionId(val){
            if(val === null){
                this.newQuestion();
            }else{
                this.loadQuestion();
            }
        }
    },
    computed:{
		...Vuex.mapState({
            authUser: state => state.auth.user,
            authToken: state => state.auth.token,
        })
    },
    mounted(){
        if(this.questionId){
            this.loadQuestion();
        }
    },
    methods:{
        addValidation(){
            let form = document.getElementById("questionForm");
            form.classList.add('was-validated');
        },
        cancelQuestion(){
            this.newQuestion();
            this.$emit('onCancel');
        },
        async saveQuestion(){
            try{
                let res;
                let type = 'create';
                this.$refs.questionFormSpinner.show();
                if(this.questionId){
                    res = await request('POST', `/question/update?token=${this.authToken}`, {questionId:this.questionId, ...this.question}, null, true);
                    type = 'edit';
                }else{
                    res = await request('POST', `/question/create?token=${this.authToken}`, {contentId: this.contentId, courseId: this.courseId, ...this.question}, null, true);
                }
                this.$emit('onSave', {id:res._id, type:type});
                this.$refs.questionFormSpinner.hide();
            }catch(err){
                console.log(err);
                this.$emit('onError', err);
                this.$refs.questionFormSpinner.hide();
            }
        },
        async loadQuestion(){
            try{
                this.$refs.questionFormSpinner.show();
                this.question = await request('POST', '/question/read', { 'questionId': this.questionId}, this.authToken );
                this.$emit('onRead', this.question);
                this.$refs.questionFormSpinner.hide();
            }catch(err){
                console.log(err);
                this.$refs.questionFormSpinner.hide();
            }
        },
        newQuestion(){
            this.question = {
                _id:null,
                name: null,
                text: null,
                correctAnswerIndex: null,
                answers:[null, null, null, null]
            }
        }

    },
    data(){
        return{
            choices: ['A. ', 'B. ', 'C. ', 'D. '],
            editPassword : true,
            question:{
                _id:null,
                name: null,
                text: null,
                correctAnswerIndex: null,
                answers:[null, null, null, null]
            },
            verifyPassword: null,
            vpError: false
        }
    }
}

</script>
