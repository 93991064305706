<template>
    <div>
        <spinner ref="tableSpinner"/>

        <div class="input-group mt-3" style="width:50%;margin-left: 10px;" v-if="enableSearch">
            <input type="text" class="form-control" placeholder="" aria-label="search" aria-describedby="search" v-model="search">
            <button class="btn btn-primary" type="button" disabled><i class="fa-solid fa-magnifying-glass"></i></button>
        </div>

        <template v-if="showList">

            <div style="padding:10px" v-if="enableTopPagination">
                <div class="">
                    <small class="text-muted">
                    Page {{ current_page }} of {{ total_pages }} | {{ total_records }}
                    Records
                    </small>
                </div>
                <div class="d-flex flex-row justify-content-between flex-nowrap" style="width: 100%;">
                    <div class="" v-if="pageSizes.length > 0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <label class="input-group-text d-none d-md-block d-lg-block">Page Size</label>
                            </div>
                            <select id="pagination-pages-bottom" class="custom-select" style="padding: 0.475rem 0.75rem; !important" v-model="page_size" @change="changePageSize">
                                <option v-for="(size, index) in pageSizes" :key="'pagination-option-bottom-'+index" :value="size">
                                    {{ size }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="">
                        <div class="btn-group w-100">
                            <button class="btn btn-outline-secondary" @click.prevent="firstPage"><i
                                class="fas fa-angle-double-left fa-fw"></i></button>
                            <button class="btn btn-outline-secondary" @click.prevent="prevPage" :disabled="current_page == 1"><i
                                class="fas fa-angle-left fa-fw"></i></button>
                            <button class="btn btn-outline-secondary" @click.prevent="nextPage" :disabled="current_page == total_pages"><i
                                class="fas fa-angle-right fa-fw"></i></button>
                            <button class="btn btn-outline-secondary" @click.prevent="lastPage"><i
                                class="fas fa-angle-double-right fa-fw"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <slot name="header"></slot>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="records.length > 0">
                            <tr v-for="(row,indexRow) in records">
                                <slot name="body" :row="row" :index="indexRow"></slot>
                            </tr>
                        </template>
                        <template v-else>
                            <div style="padding:10px">No records found</div>
                        </template>
                    </tbody>
                </table>
            </div>

            <div style="padding:10px" v-if="enableBottomPagination">
                <div class="">
                    <small class="text-muted">
                    Page {{ current_page }} of {{ total_pages }} | {{ total_records }}
                    Records
                    </small>
                </div>
                <div class="d-flex flex-row justify-content-between flex-nowrap" style="width: 100%;">
                    <div class="" v-if="pageSizes.length > 0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <label class="input-group-text d-none d-md-block d-lg-block">Page Size</label>
                            </div>
                            <select id="pagination-pages-bottom" class="custom-select" style="padding: 0.475rem 0.75rem; !important" v-model="page_size" @change="changePageSize">
                                <option v-for="(size, index) in pageSizes" :key="'pagination-option-bottom-'+index" :value="size">
                                    {{ size }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="">
                        <div class="btn-group w-100">
                            <button class="btn btn-outline-secondary" @click.prevent="firstPage"><i
                                class="fas fa-angle-double-left fa-fw"></i></button>
                            <button class="btn btn-outline-secondary" @click.prevent="prevPage" :disabled="current_page == 1"><i
                                class="fas fa-angle-left fa-fw"></i></button>
                            <button class="btn btn-outline-secondary" @click.prevent="nextPage" :disabled="current_page == total_pages"><i
                                class="fas fa-angle-right fa-fw"></i></button>
                            <button class="btn btn-outline-secondary" @click.prevent="lastPage"><i
                                class="fas fa-angle-double-right fa-fw"></i></button>
                        </div>
                    </div>
                </div>
            </div>
       </template>
    </div>
</template>

<script>
import spinner from '../spinner'
import request from '../../../helpers/request'
import Vuex from 'vuex'
var timeout;
export default {
  name: 'custom-table',
  props: {
    pageSizes: {
        type: Array,
        default: () => [5, 10, 15, 20, 50, 100]
    },
    source:{
        type: String,
        required: true
    },
    options:{
        type: Object,
        default: () => {return{filters: null}}
    },
    enableTopPagination:{
        type: Boolean,
        default: false
    },
    enableBottomPagination:{
        type: Boolean,
        default: true
    },
    enableSearch:{
        type: Boolean,
        default: false
    },
    params:{
        type: Object,
        default: () => { return {} }
    },
    showOnlyIfHasRecords:{
        type: Boolean,
        default: false
    }

  },
  components:{
    'spinner':  spinner,
  },
  computed:{
	...Vuex.mapState({
        auth: state => state.auth.user,
        authToken: state => state.auth.token,
    })
  },
  watch:{
    options:{
        handler(val, oldVal){
            this.setOptions();
        },
        deep:true
    },
    search(val, oldVal){
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            this.getRecords();
        }, 1500);
    }
  },
  methods: {
    async getRecords() {
        try{
            this.$refs.tableSpinner.show();
            this.records = [];
            let res = await request('POST', this.source ,  {
                ...this.params,
                filters: this.filters, 
                limit: this.page_size,
                sort: this.sort, 
                skip: this.page_size*(this.current_page-1),
                search: this.enableSearch && this.search && this.search.length > 0 ? this.search : null,
                pagination: true 
            }, 
            this.authToken);
            if(this.showOnlyIfHasRecords) this.showList = res.records.length > 0;
            this.$emit('onList', res);
            this.records = res.records;
            this.current_page = res.page;
            this.page_size = res.page_size;
            this.total_pages = res.total_pages;
            this.total_records = res.total_records;
            this.$refs.tableSpinner.hide();
        }catch(err){
            console.log(err);
            this.$emit('onError', err);
            this.$refs.tableSpinner.hide();
        }
    },
    setOptions(){
        this.filters = this.options.filters?JSON.parse(JSON.stringify(this.options.filters)):null;
        this.current_page = this.options.current_page?JSON.parse(JSON.stringify(this.options.current_page)):this.current_page;
        this.sort = this.options.sort?JSON.parse(JSON.stringify(this.options.sort)):this.sort;
        this.getRecords();
    },
    changePageSize() {
        this.current_page = 1;
        this.getRecords();
    },
    prevPage() {
      if (this.current_page > 1) {
        this.current_page -= 1;
        this.getRecords();
      }
    },
    firstPage() {
      this.current_page = 1;
      this.getRecords();
    },
    lastPage() {
      this.current_page = this.total_pages;
      this.getRecords();
    },
    nextPage() {
      if (this.current_page < this.total_pages) {
        this.current_page += 1;
        this.getRecords();
      }
    }
  },
  mounted() {
    this.setOptions();
  },
  data() {
    return {
        page_size: 50,
        current_page: 1,
        total_records: 0,
        total_pages: 0,
        filters:null,
        sort:null,
        records: [],
        search:null,
        showList: true,
    }
  }
}
</script>
<style scoped>
.round {
  border-radius: 50% !important;
  padding: 6px !important;
}

.icon {
  margin: 4px 5px 0 5px;
}
</style>