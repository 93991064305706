<template>
  <div>
    <header-menu/>
    <router-view style="margin-top: 72px;margin-bottom: 120px;"></router-view>
    <footer class="fixed-bottom container-fluid text-bg-light">
      <p class="text-center">South Florida School of Real Estate© 2023 • All Rights Reserved <span class="text-muted ml-4">v1.0.0</span></p>
    </footer>
  </div>
</template>
<script>
import headerMenu from './../../components/app/headerMenu'
export default {
  name: 'app-main',
  components: {
    'header-menu' : headerMenu,
  }
}
</script>

<style>
footer {
  padding-top: 10px;
  color: white;
  font-size: 16px;
}
</style>