<template>
    <div class="container">
        <div class="page-margin">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/courses">Courses</a></li>
                  <li class="breadcrumb-item" v-if="courseId" ><a :href="`/course/details/${courseId}`"> {{ courseName }}</a></li>
                  <li class="breadcrumb-item active" aria-current="page"> Enrolled Users </li>
                </ol>
            </nav>
            <button type="button" class="btn btn-primary" @click.prevent="openModalEnrolUser" style="float:right;">Enrol User <i class="fas fa-plus"></i></button>
            <h2>Enrolled Users <small class="text-muted"> {{ courseName }} </small> </h2>

            <alert ref="enrolUserAlert" :body="alert.message" :type="alert.type"/>

            <div class="card" style="margin-top:10px">
                <custom-table source="/course/user/list" :params="{'courseId': courseId}" ref="courseUserList" :enableSearch="false">
                    <template #header>
                        <th style="width: 30%;">Full Name</th>
                        <th style="width: 20%;">Email address</th>
                        <th style="width: 20%;">Status</th>
                        <th style="width: 20%;">Approved</th>
                        <th style="width: 10%;"></th>
                    </template>
                    <template #body="{row, index}">
                        <td>
                            <router-link class="btn btn-link" title="show User" :to="`/user/details/${row.userId}?name=${row.userFullName}`" > {{ row.userFullName }} </router-link>
                        </td>
                        <td>
                            {{ row.userEmailAddress }}
                        </td>
                        <td>
                            <span class="text-success" v-if="row.active"> Active </span>
                            <span class="text-danger" v-else> Inactive </span>
                        </td>
                        <td>
                            <span class="text-success" v-if="row.approved"> <i class="fa-solid fa-check"></i> </span>
                            <span class="text-danger"  v-else-if="!row.active && !row.approved"> <i class="fa-solid fa-xmark"></i> </span>
                            <span class="text-secondary" v-else> In progress... </span>
                        </td>
                        <td>
                            <router-link class="btn btn-link btn-sm" title="show User" :to="`/user/details/${row.userId}?name=${row.userFullName}`" ><i class="fas fa-eye"></i></router-link>
                            <a class="btn btn-link btn-sm" title="show user progress Details" href="" @click.prevent="detailsCourseUserModalOpen(row)"><i class="fas fa-gear"></i></a>
                            <a class="btn btn-link btn-sm" title="delete user" href="" @click.prevent="deleteCourseUser(row._id)"><i class="fas fa-trash-alt"></i></a>
                        </td>
                    </template>
                </custom-table>
            </div>
        </div>

        <!-- detailsCourseUserModal -->
        <div class="modal fade" id="detailsCourseUserModal" tabindex="-1" aria-labelledby="detailsCourseUserModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="detailsCourseUserModalLabel">Final Examinations</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click.prevent="detailsCourseUserModalClose"></button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-sm">
                            <thead>
                              <tr>
                                <th scope="col" style="width: 35%;">Name</th>
                                <th scope="col" style="width: 20%;">Grade for pass</th>
                                <th scope="col" style="width: 20%;">Grade </th>
                                <th scope="col" style="width: 20%;">Approved</th>
                                <th scope="col" style="width: 5%;"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="exam of activeCourseUser.exams">
                                <td>{{ exam.name}}</td>
                                <td>
                                    <span> {{ exam.gradeToPass }} </span>
                                </td>
                                <td>
                                    <span v-if="!exam.active"> {{ exam.grade }} </span>
                                </td>
                                <td>
                                    <template v-if="!exam.active"> 
                                        <span class="text-success" v-if="exam.approved"> <i class="fa-solid fa-check"></i> </span>
                                        <span class="text-danger"  v-else> <i class="fa-solid fa-xmark"></i> </span>
                                    </template>
                                </td>
                                <td>
                                    <template v-if="!exam.active">
                                        <a
                                            :href="`/course/view/${activeCourseUser.courseId}/${exam.contentId}?exam=${exam.examId}`"
                                            class="btn btn-link btn-sm" title="show exam deatils" >
                                            <i class="fas fa-eye"></i>
                                    </a>
                                    </template>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- enrolUserModal -->
        <div class="modal fade" id="enrolUserModal" tabindex="-1" aria-labelledby="enrolUserModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="enrolUserModalLabel">Enrol User on <small class="text-muted"> {{ courseName }} </small></h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <custom-selector v-model:value="userSelected" source="/user/list" id="enrol-user-selector" label="Select user" :filters="{'role':2,'active':true}">
                            <template #value="{record}">  
                                <span v-if="record"> 
                                    {{ `${record.firstName?record.firstName:''} ${record.middlename?record.middlename:''} ${record.lastName?record.lastName:''}`}} 
                                    <small class="text-muted">{{ record.email?record.email:'' }}</small>
                                </span>
                            </template>
                            <template #option="{record}">
                                {{ `${record.firstName?record.firstName:''} ${record.middlename?record.middlename:''} ${record.lastName?record.lastName:''}`}} <small class="text-muted">{{ record.email?record.email:'' }}</small>
                            </template>
                        </custom-selector>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click.prevent="enrollUser()" :disabled="userSelected == null">Save <i class="far fa-save"></i></button>
                        <button type="button" class="btn btn-danger" @click.prevent="closeModalEnrolUser">Cancel <i class="fa-solid fa-xmark"></i> </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import selector from '../../../components/utilities/selector'
import {Modal} from 'bootstrap'
import table from '../../../components/utilities/table'
import request from '../../../helpers/request'
import alert from '../../../components/utilities/alert';
import Vuex from 'vuex'

export default {
    name: 'courseUserList',
    components:{
        'custom-table': table,
        'custom-selector': selector,
        'alert': alert
    },
    computed:{
        ...Vuex.mapState({ authToken: state => state.auth.token }),
        courseId(){
            return this.$route.params.courseId;
        },
        courseName(){
            return this.$route.query.name ? this.$route.query.name : 'Course';
        }
    },
    methods:{
        openAlert(msg, type, timeOut = 0){
            return new Promise((resolve, reject) =>{
                this.alert.message = msg;
                this.alert.type = type;
                this.$refs['enrolUserAlert'].open();
                setTimeout(() => {
                    this.$refs['enrolUserAlert'].close();
                    resolve(true);
                }, timeOut);
            })
            
        },
        detailsCourseUserModalOpen(courseUser){
            this.activeCourseUser =  courseUser;
            this.detailsCourseUserModal.show();
        },

        detailsCourseUserModalClose(){
            this.detailsCourseUserModal.hide();
            this.activeCourseUser = { exams:[] };
        },

        openModalEnrolUser(){
            this.userSelected =  null;
            this.enrolUserModal.show();
        },

        closeModalEnrolUser(){
            this.enrolUserModal.hide();
        },

        async enrollUser(){
            try{
                const confirm = window.confirm("Are you sure, of enroll this user?")
                if(confirm){
                    await request('POST', '/course/user/create', {courseId: this.courseId, userId: this.userSelected._id}, this.authToken);
                    this.getCourseUsers();
                    this.closeModalEnrolUser();
                    this.openAlert(`Enroll User Succesfully`, 'success', 1500);
                }
            }catch(err){
                this.closeModalEnrolUser();
                this.openAlert(`Error : ${err}`, 'danger', 5500);
            }
        },

        async deleteCourseUser(courseUserId){
            try{
                const confirm = window.confirm("Are you sure, of remove this user?")
                if(confirm){
                    await request('POST', '/course/user/delete', {courseUserId: courseUserId}, this.authToken);
                    this.getCourseUsers();
                }
            }catch(err){
                console.log(err);
            }
        },

        getCourseUsers(){
            this.$refs.courseUserList.getRecords();
        }
    },
    mounted(){
        this.enrolUserModal = new Modal(document.getElementById('enrolUserModal'));
        this.detailsCourseUserModal = new Modal(document.getElementById('detailsCourseUserModal'));
    },
    data(){
        return {
            userSelected: null,
            enrolUserModal: null,
            activeCourseUser: {exams:[]},
            alert:{
                message: null,
                type: null
            }
        }
    }
}
</script>