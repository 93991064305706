<template>
    <div class="container">
        <div class="page-margin">
            <h2>Question</h2>
            <div class="row">
                <div class="col-12">
                    <alert ref="questionListAlert" :body="alert.message" :type="alert.type"/>
                    <question-form @onSave="onSave" @onError="onError" :courseId="courseId" :contentId="contentId" :questionId="questionId" @onCancel="onCancel"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import alert from '../../../components/utilities/alert';
import questionForm from "../../../components/app/question/question_form"
export default {
    name: 'question-crud',
    components: {
        'question-form' : questionForm,
        'alert': alert
    },
    methods:{
        openAlert(msg, type, timeOut = 0, redirectPath = null){
            this.alert.message = msg;
            this.alert.type = type;
            this.$refs['questionListAlert'].open();
            setTimeout(() => {
                this.$refs['questionListAlert'].close();
                if(redirectPath) this.$router.push(redirectPath);
            }, timeOut);
        },
        onSave(evt){
            this.openAlert(`question ${evt.type=='create'?'Created':'Updated'} Succesfully`, 'success', 2000, `/course/${this.courseId}/content/read/${this.contentId}?type=exam`);
        },
        onError(err){
            this.openAlert(`Error : ${err}`, 'danger', 3500);
        },
        onCancel(evt){
            this.$router.push(`/course/${this.courseId}/content/read/${this.contentId}?type=exam`);
        }
    },  
    computed:{
        type(){
            return this.$route.params.type;
        },
        courseId(){
            return this.$route.params.courseId||null;
        },
        contentId(){
            return this.$route.params.contentId||null;
        },
        questionId(){
            return this.$route.params.id||null;
        }
    },
    data(){
        return {
            alert:{
                message: null,
                type: null
            }
        }
    }
}


</script>