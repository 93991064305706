import { createStore } from 'vuex'
import decodeJWT from '../helpers/decodeJWT';
import validateToken from '../helpers/validateToken';
import settings from '../../config.js'

const auth = {
    namespaced: true,
    state: {
      suffix: `__${settings.APP_NAME}-`,
      token: null,
      user: {},
    },
    mutations: {
      setAuth(state, auth) {
        state.token = auth.token;
        state.user = auth.user;
      }
    },
    actions: {
        login({ state, commit, dispatch }, token) {
          try {
            window.localStorage.removeItem(state.suffix + 'token');
            let dataUser = decodeJWT(token);
            window.localStorage.setItem(state.suffix + 'token', token);
            commit("setAuth", { user: dataUser, token: token });
          } catch (err) {
            console.log(err);
          }
        },
        logout({ state, commit }) {
          window.localStorage.removeItem(state.suffix + 'token');
          window.localStorage.removeItem(state.suffix + 'enableEdit');
          window.localStorage.removeItem(state.suffix + 'CurrentSessionExam');
          commit('setAuth', { user: {}, token: null });
        },
        async init({ state, commit, dispatch }) {
          try {
            let token = window.localStorage.getItem(state.suffix + 'token');
            if (token) {
              let dataUser = decodeJWT(token);
              commit("setAuth", { user: dataUser, token: token });
              await validateToken(token);
            }else{
              throw 'invalid token';
            }
          } catch (err) {
            console.log(err);
            dispatch('logout');
          }
        }
    }
};

export default createStore({
    modules: {
        auth: auth
    }
})