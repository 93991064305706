<template>
  <div class="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark" :class="[{'hide-nav':isHidden}]" style="width: 285px;height:87vh;position:fixed;z-index:2;overflow-y: auto;overflow-x: hide;">
    <div class="d-flex" style="align-items:center; margin-bottom:5px">
      <button class="btn btn-outline-secondary" @click.prevent="isHidden = !isHidden"> 
        <i class="fa-solid fa-xmark"></i> 
      </button>
      <div class="form-check form-switch" style="margin-left:auto" v-if="validateRole(1)">
        <input class="form-check-input" type="checkbox" role="switch" id="enableEditsSwitch" v-model="cEnableEdit">
        <label class="form-check-label" for="enableEditsSwitch" >Edit mode</label>
      </div>
    </div>
    <nav-link :courseId="courseId" :links="links"></nav-link>
  </div>
  <div v-if="isHidden" style="position:fixed;z-index:2;margin-left:15px;margin-top:20px">
    <button class="btn btn-outline-secondary" @click.prevent="isHidden = !isHidden">
      <i class="fa-solid fa-list"></i> 
    </button>
  </div>
</template>

<script>
import navLinks from './navLinks.vue'
import validateRole from '../../../../mixins/validateRole'
export default {
  name: 'course-nav',
  mixins: [validateRole],
  components:{
    'nav-link': navLinks
  },
  watch:{
    enableEdit(val){
      this.cEnableEdit = val;
    },
    cEnableEdit(val){
      this.$emit('onChangeEnableEdit',val);
    },
    isHidden(val){
      this.$emit('onHidden', val);
    }
  },
  props:{
    links:{
      type: Array,
      required: true
    },
    courseId:{
      type: String,
      required: true
    },
    courseName:{
      type: String,
      required: true
    },
    enableEdit:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      cEnableEdit:this.enableEdit,
      isHidden: false
    }
  }
} 
</script>

<style> 
.hide-nav{
  visibility: collapse;
}

</style>