<template>
    <form id="recoveryForm" @submit.prevent="submitRecoveryForm">
        <div style="margin-top:15px">
            <div class="card">
                <div class="card-body row">
                    <div class="mb-3 col-12">
                        <label for="user_email">Email <span style="color:red">*</span> </label>
                        <input type="email" class="form-control" placeholder="Your Email address" id="user_email" v-model="email" autocomplete="new-password" required>
                        <div class="invalid-feedback">
                            Please provide a valid Email.
                        </div>
                    </div>

                    <div class="text-success" v-if="success">
                        A recovery message has been sent to the registered email account, you can update your password by clicking on the link provided.
                    </div>
            
                    <div class="col-12 clearfix" style="margin-top:15px">
                        <button type="submit" class="btn btn-primary float-end" @click="addValidation" > Reset Password <i class="far fa-save"></i></button>
                    </div>

                </div>
            </div>
        </div>
        <spinner ref="recoveryFormSpinner"/>
    </form>
</template>

<script>
import request from '../../../../helpers/request'
import spinner from '../../../utilities/spinner'

export default {
    name: "recoveryForm",
    components:{
        'spinner':  spinner
    },
    methods:{
        addValidation(){
            let form = document.getElementById("recoveryForm");
            form.classList.add('was-validated');
        },
        async submitRecoveryForm(){
            try{
                this.success = false;
                this.$refs.recoveryFormSpinner.show();
                let res = await request('POST', '/recovery', { email: this.email }, this.authToken);
                this.$refs.recoveryFormSpinner.hide();
                this.success = true;
                this.$emit('onSend', res );
            }catch(err){
                console.log(err);
                this.success = false;
                this.$emit('onError', err);
                this.$refs.recoveryFormSpinner.hide();
            }
        }
    },
    data(){
        return{
            email: null,
            success: false
        }
    }
}
</script>