const decodeJWT = (token) => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const data = JSON.parse(window.atob(base64));
        return (data);
    } catch (error) {
        throw error;
    }
}

export default decodeJWT;