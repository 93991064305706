<template>
    <div>
        <vue-editor
            ref="v-editor" 
            @image-added="handleFileAdded"
            :useCustomImageHandler="useCustomFileHandler"
            :editor-toolbar="customToolbar"
            v-model="content"
        />
        <spinner ref="editorSpinner"/>
    </div>
</template>

<script>
import { VueEditor } from "./vue3-editor";
import spinner from '../spinner';
import request from '../../../helpers/request'
import fileTypeIs from '../../../mixins/fileTypeIs'

import Vuex from 'vuex'

export default {
    name: 'editor',
    mixins:[fileTypeIs],
    components: {
        VueEditor,
        spinner
    },
    computed:{
		...Vuex.mapState({
            authToken: state => state.auth.token,
        })
    },
    props:{
        id:{
            type:String,
            default: 'editor'
        },  
        value:{
            type: String
        },
        disabled:{
            type: Boolean,
            default: false
        },
        options:{
            type: Array
        },
        placeholder:{
            type: String
        },
        useCustomFileHandler:{
            type: Boolean,
            default: false
        },
        customFileHandlerUrlUpload:{
            type: String,
            default:null
        },
        customFileHandlerUrlSource:{
            type: String,
            default:null
        },
        customToolbar:{
            default : () => []
        }
    },
    watch:{
        content(val){
            this.$emit('update:value', val);
        },
        value(val){
            this.content = val;
        }
    },
    methods: {
        async handleFileAdded(file, Editor, cursorLocation, resetUploader) {
            try{
                this.$refs.editorSpinner.show();
                if(this.customFileHandlerUrlUpload){
                    let formData = new FormData();
                    formData.append("files", file);
                    let res = await request('POST', `${this.customFileHandlerUrlUpload}?token=${this.authToken}`, formData, null, true);
                    let ext = res[0].split('.')[1];
                    if(this.fileTypeIs('pdf', ext)){
                        Editor.pasteHTML(cursorLocation, `<iframe src="${this.customFileHandlerUrlSource}/${res[0]}" height="1100" width="100%"></iframe>`);
                    }else if(this.fileTypeIs('video', ext)){
                        Editor.pasteHTML(cursorLocation, `<iframe src="${this.customFileHandlerUrlSource}/${res[0]}" height="100%" width="100%"></iframe>`);
                    }if(this.fileTypeIs('image', ext)){
                        Editor.insertEmbed(cursorLocation, "image", `${this.customFileHandlerUrlSource}/${res[0]}`);
                    }
                    Editor.focus();
                    resetUploader();
                    this.$refs.editorSpinner.hide();
                }else{
                    throw 'Invalid URL Source';
                }
            }catch(err){
                console.log(err);
                this.$emit('onFileUploadError',err);
                this.$refs.editorSpinner.hide();
            }
        }
    },  
    data(){
        return{
            content: this.value
        }
    }
}

</script>