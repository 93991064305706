<template>
    <div>
        <div class="container">
            <div class="page-margin">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="/courses">Courses</a></li>
                      <li class="breadcrumb-item active" aria-current="page"> Quick Payment </li>
                    </ol>
                </nav>
                <div class="row">
                    <h1>looks like something went wrong!</h1>
                    <p>looks like something went wrong while trying to process your payment. If you have any questions please contact us at 305-401-4516 or send us an email info@sflschoolofrealestate.com</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name: 'quick-payment-fail'
}
</script>