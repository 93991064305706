<template>
    <div class="container">
        <div class="page-margin">
            <button v-if="validateRole(1)" type="button" class="btn btn-primary" @click="$router.push('course/create')" style="float:right;">New <i class="fas fa-plus"></i></button>
            <h2>Courses</h2>
            <div class="card" style="margin-top:10px">
                <custom-table 
                    :source="validateRole(1)?'/course/list':'/course/pList'" 
                    ref="courseList" 
                    :enableSearch="true"
                >
                    <template #header>
                        <th style="width: 50%;">Course</th>
                        <th> Price</th>
                        <th style="width: 20%;" v-if="validateRole(1)"> Status</th>
                        <th style="width: 15%;"></th>
                    </template>
                    <template #body="{row, index}">
                        <td @click.prevent="$router.push(`/course/details/${row._id}`)" style="cursor:pointer">
                            <div class="course-image-list" :style="`background-image: url(${getCourseImage(row.image)})`">
                                <span class="sr-only">Course image</span>
                            </div>
                            <span> {{ row.fullName }} </span>
                        </td>
                        <td>
                            $ {{ currencyFormat(row.price) }}
                        </td>
                        <td v-if="validateRole(1)">
                            <span>{{ row.active?'Active':'Inactive'}}</span>
                        </td>
                        <td>
                            <template v-if="validateRole(1)">
                                <router-link class="btn btn-link btn-sm" title="Show Course Details" :to="`/course/details/${row._id}`"> <i class="fa-solid fa-gear"></i> </router-link>
                                <router-link class="btn btn-link btn-sm" title="Edit Settings course" :to="`/course/read/${row._id}`" ><i class="fas fa-edit"></i></router-link>
                                <router-link class="btn btn-link btn-sm" title="Course Participants" :to="`/course/users/${row._id}?name=${row.fullName}`" ><i class="fas fa-users"></i></router-link>
                                <router-link class="btn btn-link btn-sm" title="Course Final Exams" :to="`/course/exams/${row._id}?name=${row.fullName}`" ><i class="fas fa-list"></i></router-link>
                                <a class="btn btn-link btn-sm" title="Delete course" href="" @click.prevent="deleteCourse(row)" ><i class="fas fa-trash-alt"></i></a>
                            </template>
                            <template v-else>
                                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                    <input type="hidden" name="cmd" value="_xclick">
                                    <input type="hidden" name="business" value="72PKKD3WCLA9Q">
                                    <input type="hidden" name="lc" value="US">
                                    <input type="hidden" name="item_name" :value="row.fullName">
                                    <input type="hidden" name="item_number" :value="`${row._id}-${user._id}`">
                                    <input type="hidden" name="amount" :value="row.price">
                                    <input type="hidden" name="currency_code" value="USD">
                                    <input type="hidden" name="button_subtype" value="services">
                                    <input type="hidden" name="no_note" value="1">
                                    <input type="hidden" name="no_shipping" value="1">
                                    <input type="hidden" name="rm" value="1">
                                    <input type="hidden" name="return" :value="`${app.URL_SITE}/payment/success`">
                                    <input type="hidden" name="cancel_return" :value="`${app.URL_SITE}/payment/fail`">
                                    <input type="hidden" name="bn" value="PP-BuyNowBF:btn_buynow_LG.gif:NonHosted">

                                    <input class="btn btn btn-outline-success" type="submit" name="" :value="`$ ${row.price} Buy Now`">
                                </form>
                            </template>
                        </td>
                    </template>
                </custom-table>
            </div>
        </div>
    </div>
</template>

<script>
import table from '../../../components/utilities/table'
import request from '../../../helpers/request'
import validateRole from '../../../mixins/validateRole'
import Vuex from 'vuex'

export default {
    name: 'course-list',
    mixins: [validateRole],
    components:{
        'custom-table': table
    },
    computed:{
        ...Vuex.mapState({
            user: state => state.auth.user,
            authToken: state => state.auth.token,
        })
    },  
    methods:{
        currencyFormat(price){
            return price ? parseFloat(price).toFixed(2) : price;
        },
        error(err){
            console.log(err);
        },
        async deleteCourse(course){
            try{
                let message = course.courseParentId ? "Are you sure, of delete this course?" : "Are you sure, of delete this course?\nThis is a course Parent, the child courses will be deleted";
                const confirm = window.confirm(message);
                if(confirm){
                    await request('POST', '/course/delete', {'course': course._id}, this.authToken);
                    this.getCourses();
                }
            }catch(err){
                console.log(err);
            }
        },
        getCourses(){
            this.$refs.courseList.getRecords();
        },
        getCourseImage(path){
            return `${app.API}/${path}`;
        }
    },
    data(){
        return {
            app: app
        }
    }
}
</script>