<template>
  <div class="d-flex">
    <course-nav :courseName="course.fullName" :enableEdit="enableEdit" :courseId="courseId" :links="course.content" @onChangeEnableEdit="onChangeEnableEdit" @onHidden="navHidden = $event"></course-nav>
    <div class="container-fluid" :class="[{'container-margin-nav-show': !navHidden, 'container-margin-nav-hidden': navHidden}]">
      <div style="margin-top:20px;">
        <div class="d-flex" style="align-items:center;margin-bottom:5px" v-if="content._id">
          <course-breadcrum :courseName="course.fullName" :courseId="courseId" :content="content"/>
        </div>
        <div class="d-flex" style="align-items:center;margin-bottom:5px" v-else>
          <h2 style="color: #505050">{{ course.fullName }}</h2>
          <div style="margin-left:auto;" v-if="enableEdit">
            <a href="#" class="btn btn-outline-dark btn-sm" v-if="!this.contentId" @click.prevent="openModalNewContent()"><i class="fa fa-plus"></i> Add Main Content</a>
            <router-link :to="`/course/read/${course._id}`" class="btn btn-outline-dark btn-sm" style="margin-left:4px" ><i class="fa fa-edit"></i></router-link>
          </div>
        </div>

        <h2 style="color: #505050">{{ content.name }}</h2>
        <content-view :courseId="courseId" :enableEdit="enableEdit" :contents="activeContents" @onNewContent="openModalNewContent($event)"/>

        <!-- New Content or Activity Modal -->
        <div class="modal fade" id="typeContentModal" tabindex="-1" aria-labelledby="typeContentModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="typeContentModalLabel">Add New Content or Resource</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="newContentId = null"></button>
              </div>
              <div class="modal-body row">
                <div class="col-4" style="text-align: center" @click.prevent="addContent('page')">
                  <a href="" class="btn btn-primary btn-lg"><i class="fa fa-file-lines"></i></a>
                  <br>
                  <span>Page</span>
                </div>
                <div class="col-4" style="text-align: center" @click.prevent="addContent('file')">
                  <a href="" class="btn btn-info btn-lg"><i class="fa fa-file"></i></a>
                  <br>
                  <span>File</span>
                </div>
                <div class="col-4" style="text-align: center" @click.prevent="addContent('exam')">
                  <a href="" class="btn btn-secondary btn-lg"><i class="fa fa-list-check"></i></a>
                  <br>
                  <span>Exam</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <spinner ref="courseViewSpinner"/>
  </div>
</template>

<script>
import {Modal} from 'bootstrap'
import spinner from '../../../components/utilities/spinner'
import request from '../../../helpers/request'
import courseNav from '../../../components/app/course/course_nav'
import contentView from '../../../components/app/content/content_view'
import courseBreadcrum from '../../../components/app/course/course_breadcrum'

import Vuex from 'vuex'

export default {
  name: 'course-view',
  emits:["onDelete"],
  components:{
    'spinner':  spinner,
    'course-nav': courseNav,
    'content-view': contentView,
    'course-breadcrum':courseBreadcrum
  },
  watch:{
    contentId(val){
      if(val !== null){
        this.getContent();
      }else{
        this.content = {_id:null};
      } 
    }
  },
  computed:{
    ...Vuex.mapState({
      authToken: state => state.auth.token,
      suffix: state => state.auth.suffix
    }),
    courseId(){
      return this.$route.params.id;
    },
    contentId(){
      return this.$route.params._contentId?this.$route.params._contentId:null;
    },
    activeContents(){
      return this.contentId && this.content._id!==null?[this.content]:this.course.content;
    }
  },
  methods:{
    openModalNewContent(contentId = null){
      this.newContentParentId = contentId;
      this.typeContentModal.show();
    },
    addContent(type){
      this.typeContentModal.hide();
      if(this.newContentParentId == null) this.$router.push(`/course/${this.courseId}/content/create?type=${type}`);
      else this.$router.push(`/course/${this.courseId}/content/create/${this.newContentParentId}?type=${type}`);
    },
    async getCourseContent(){
      try{
        this.$refs.courseViewSpinner.show();
        this.course = await request('POST', '/course/content', { 'courseId': this.courseId}, this.authToken );
        this.$refs.courseViewSpinner.hide();
      }catch(err){
        console.log(err);
      }
    },
    async getContent(){
      try{
          this.$refs.courseViewSpinner.show();
          this.content = {_id:null};
          this.content = await request('POST', '/content/read', { 'courseId': this.courseId,  'contentId': this.contentId}, this.authToken );
          this.$refs.courseViewSpinner.hide();
      }catch(err){
          console.log(err);
          this.$refs.courseViewSpinner.hide();
          //if(this.contentId) this.$router.push(`/course/${this.courseId}`);
      }
    },
    onChangeEnableEdit(evt){
      window.localStorage.setItem(this.suffix + 'enableEdit', evt);
      this.enableEdit = evt;
    }
  },
  async mounted(){
    this.typeContentModal = new Modal(document.getElementById('typeContentModal'));
    let storageEnableEdit = window.localStorage.getItem(this.suffix + 'enableEdit');
    this.enableEdit = typeof storageEnableEdit !== 'undefined'?(storageEnableEdit === 'true'?true:false):false;
    this.getCourseContent();
    if(this.contentId) this.getContent();
  },
  data(){
    return{
      newContentParentId:null,
      course:{fullName:'', content:[]},
      content: {_id:null},
      enableEdit: false,
      typeContentModal: null,
      navHidden: false
    }
  }
}
</script>

<style>

.course-contents li {
  list-style-type: none;
}

.container-margin-nav-show {
  margin-left: 280px !important;
}

.container-margin-nav-hidden {
  margin-left: 50px !important;
}

</style>
