<template>
    <div class="container" style="margin-top: 90px;margin-bottom: 120px;">
        <header-menu/>
        <div class="page-margin">
            <h2>Register</h2>
            <div class="row">
                <div class="col-12">
                    <alert ref="userListAlert" :body="alert.message" :type="alert.type"/>
                    <user-form
                        saveLabel="Register"
                        saveIconClass="fa fa-user" 
                        :onlyRegister="true" 
                        @onSave="onSave" 
                        @onError="onError" 
                        @onCancel="onCancel" 
                    />
                </div>
            </div>
        </div>
        <footer class="fixed-bottom container-fluid text-bg-light">
            <p class="text-center">South Florida School of Real Estate© 2023 • All Rights Reserved <span class="text-muted ml-4">v1.0.0</span></p>
        </footer>
    </div>
</template>

<script>
import headerMenu from './headerMenu'
import alert from '../../components/utilities/alert';
import userForm from "../../components/app/user/user_form"
export default {
    name: 'user-crud',
    components: {
        'user-form' : userForm,
        'alert': alert,
        'header-menu' : headerMenu
    },
    methods:{
        openAlert(msg, type, timeOut = 0){
            this.alert.message = msg;
            this.alert.type = type;
            this.$refs['userListAlert'].open();
            setTimeout(() => {
                this.$refs['userListAlert'].close();
            }, timeOut);
        },
        onSave(evt){
            this.openAlert('welcome, your registration has been successful', 'success', 1500);
            if(evt.token){
                this.$store.dispatch("auth/login", evt.token);
                if(this.$route.query.courseId){
                    this.$router.push(`/course/details/${this.$route.query.courseId}`);
                }else{
                    this.$router.push('/');
                }
            }
        },
        onError(err){
            this.openAlert(`Error : ${err}`, 'danger', 3500);
        },
        onCancel(evt){
            this.$router.push('/users');
        }
    },  
    computed:{
        type(){
            return this.$route.params.type;
        },
        id(){
            return this.$route.params.id||null;
        }
    },
    data(){
        return {
            alert:{
                message: null,
                type: null
            }
        }
    }
}


</script>