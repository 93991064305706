<template>
    <div class="container">
        <div class="page-margin">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/users">Users</a></li>
                  <li class="breadcrumb-item"><a :href="`/user/details/${id}`">{{ userName }}</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Settings</li>
                </ol>
            </nav>
            <h2>Settings <small class="text-muted"> {{ userName }}</small> </h2>
            <div class="row">
                <div class="col-12">
                    <alert ref="userListAlert" :body="alert.message" :type="alert.type"/>
                    <user-form :userId="id" @onSave="onSave" @onError="onError" @onCancel="onCancel"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import alert from '../../../components/utilities/alert';
import userForm from "../../../components/app/user/user_form"
export default {
    name: 'user-crud',
    components: {
        'user-form' : userForm,
        'alert': alert
    },
    methods:{
        openAlert(msg, type, timeOut = 0, back = false){
            this.alert.message = msg;
            this.alert.type = type;
            this.$refs['userListAlert'].open();
            setTimeout(() => {
                this.$refs['userListAlert'].close();
                if(back) window.history.back();
            }, timeOut);
        },
        onSave(evt){
            this.openAlert(`User ${evt.type=='create'?'Created':'Updated'} Succesfully`, 'success', 2000, true);
        },
        onError(err){
            this.openAlert(`Error : ${err}`, 'danger', 3500);
        },
        onCancel(evt){
            window.history.back();
        }
    },  
    computed:{
        type(){
            return this.$route.params.type;
        },
        id(){
            return this.$route.params.id||null;
        },
        userName(){
            return this.$route.query.name ? this.$route.query.name : 'User';
        }
    },
    data(){
        return {
            alert:{
                message: null,
                type: null
            }
        }
    }
}


</script>