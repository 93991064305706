<template>
    <div>
        <label class="mb-2" :for="id">{{ label }}</label>
        <div class="dropdown">
            <button class="btn btn-default dropdown-toggle form-control" style="border: 1px solid #ced4da;text-align: start;" type="button" :id="id" data-bs-toggle="dropdown" aria-expanded="false" @click.prevent="getRecords">
                <slot name="value" :record="cValue"></slot>
            </button>
            <div class="dropdown-menu dropdown-menu-wide" :aria-labelledby="id">
                <!-- Search section -->
                <div style="padding-right: 5px;padding-left: 5px; padding-bottom: 8px; font-size:12px; position: relative;">
                    <input type="text"
                        class="form-control form-control-sm rounded-right"
                        placeholder="Search" 
                        aria-label="search"
                        aria-describedby="search"
                        autocomplete="new-password"
                        v-model="search"
                    >
                    <span class="icon-inside-sm"><i class="fa fa-search"></i></span>
                </div>

                <div style="padding-left: 10px;" class="text-muted fa-1x" v-show="loading">
                    <i class="fas fa-spinner fa-pulse"></i>
                </div>

                <div style="max-height: 300px; overflow-x: hidden;overflow-y: auto;" v-if="records.length > 0">
                    <a class="dropdown-item" href="" @click.prevent="selectOption(record)" v-for="(record, index) of records">
                        <slot name="option" :record="record" :index="index"></slot>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import request from '../../../helpers/request'
import Vuex from 'vuex'
var timeout;
export default {
    name: 'custom-selector',
    props: {
        id:{
            type: String,
            required: true
        },
        source:{
            type: String,
            required: true
        },
        label: {
            type: String,
            default: ''
        },
        value:{
            required: true
        },
        filters:{
            type: Object,
            default: () => { return {} }
        }
    },
    watch:{
        value(val){
            this.cValue = val;
        },
        search(val){
            if(val && val.length > 2){
                if(timeout) clearTimeout(timeout);
                timeout = setTimeout(() => {
                    this.getRecords();
                }, 1500);
            }else{
                this.records = [];
            }
        }
    },
    computed:{
        ...Vuex.mapState({
            authToken: state => state.auth.token,
        })
    },
    methods:{
        async getRecords() {
            try{
                this.loading = true;
                this.records = [];
                this.records = await request('POST', this.source , {
                    filters: this.filters,
                    search: this.search,
                    pagination: false
                }, this.authToken);
                this.loading = false;
                this.$emit('onList', res);
            }catch(err){
                this.$emit('onError', err);
            }
        },
        selectOption(record){
            try{
                this.search = null;
                this.cValue = record;
                this.$emit('update:value', this.cValue);
            }catch(err){
                this.$emit('onError', err);
            }
        }
    },
    data() {
        return {
            records: [],
            search: null,
            cValue: this.value,
            loading: false 
        }
    }
}
</script>

<style>
.icon-inside-sm {
    position: absolute;
    right: 10px;
    top: calc(50% - 17px);
    pointer-events: none;
    font-size: 16px;
    font-size: 1.125rem;
    color: #c4c3c3;
    z-index: 3;
}
.dropdown-menu.dropdown-menu-wide {
    min-width: 100%;
}
</style>