<template>
    <div class="container">
        <div class="page-margin">

            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/courses">Courses</a></li>
                  <li class="breadcrumb-item active" aria-current="page">{{ course.fullName }}</li>
                </ol>
            </nav>
            <h2>{{ course.fullName }} </h2>
            <div class="row">
                <div class="col-12 course-image" :style="`background-image: url(${getCourseImage(course.image)})`">
                    <span class="sr-only">Course image</span>
                </div>
                <div class="col-12 mt-2" v-html="course.description" />
                <div class="col-12 row" v-if="validateRole(1)">
                    <div class="col-12 col-md-3 mt-2" style="text-align: center">
                        <router-link class="btn btn-primary btn-lg d-block" title="Edit Settings course" :to="`/course/read/${course._id}`" ><i class="fas fa-edit"></i> <br> Settings </router-link>
                    </div>

                    <div class="col-12 col-md-3 mt-2" style="text-align: center">
                        <router-link class="btn btn-info btn-lg d-block"  title="Course Participants" :to="`/course/users/${course._id}?name=${course.fullName}`" ><i class="fas fa-users"></i> <br> Enrolled Users</router-link>
                    </div>

                    <div class="col-12 col-md-3 mt-2" style="text-align: center">
                        <router-link class="btn btn-secondary btn-lg d-block"  title="Course Final Exams" :to="`/course/exams/${typeof course.courseParentId != 'undefined' ? course.courseParentId : course._id }?name=${course.fullName}`" ><i class="fas fa-list-check"></i> <br> Final Exams </router-link>
                    </div>

                    <div class="col-12 col-md-3 mt-2" style="text-align: center">
                        <router-link class="btn btn-warning btn-lg d-block" title="Manage Course Contents" :to="`/course/view/${course._id}`" > <i class="fas fa-file-circle-plus"></i> <br> Manage Contents </router-link>
                    </div>
                </div>
                <template v-else>
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                        <input type="hidden" name="cmd" value="_xclick">
                        <input type="hidden" name="business" value="72PKKD3WCLA9Q">
                        <input type="hidden" name="lc" value="US">
                        <input type="hidden" name="item_name" :value="course.fullName">
                        <input type="hidden" name="item_number" :value="`${course._id}-${user._id}`">
                        <input type="hidden" name="amount" :value="course.price">
                        <input type="hidden" name="currency_code" value="USD">
                        <input type="hidden" name="button_subtype" value="services">
                        <input type="hidden" name="no_note" value="1">
                        <input type="hidden" name="no_shipping" value="1">
                        <input type="hidden" name="rm" value="1">
                        <input type="hidden" name="return" :value="`${app.URL_SITE}/payment/success`">
                        <input type="hidden" name="cancel_return" :value="`${app.URL_SITE}/payment/fail`">
                        <input type="hidden" name="bn" value="PP-BuyNowBF:btn_buynow_LG.gif:NonHosted">

                        <input style="float:right" class="btn btn btn-outline-success btn-lg" type="submit" name="" :value="`$ ${course.price} Buy Now`">
                    </form>
                </template>
            </div>
        </div>
        <spinner ref="courseFormSpinner"/>
    </div>
</template>

<script>

import validateRole from '../../../mixins/validateRole'
import request from '../../../helpers/request'
import spinner from '../../../components/utilities/spinner'
import Vuex from 'vuex'
export default {
    name: 'course-details',
    mixins: [validateRole],
    components:{
        'spinner':  spinner
    },
    computed:{
        ...Vuex.mapState({
            user: state => state.auth.user,
            authToken: state => state.auth.token,
        }),
        courseId(){
            return this.$route.params.id;
        }
    },
    async mounted(){
        await this.getCourse();
    },
    methods:{
        async getCourse(){
            try{
                this.$refs.courseFormSpinner.show();
                this.course = await request('POST', '/course/read', {courseId: this.courseId}, this.authToken);
                this.$refs.courseFormSpinner.hide();
            }catch(err){
                console.log(err);
            }
        },
        getCourseImage(path){
            return `${app.API}/${path}`;
        }
    },
    data(){
        return {
            course:{
                _id: null,
                fullName: null,
                shortName: null,
                firstName: null,
                active: true,
                description: '',
                image: null   
            },
            app:app
        }
    }
}
</script>

<style>
.course-image{
    height: 160px; 
    width: 100%;
    background-position: center;
    background-size: cover;
}

</style>