<template>
    <div class="container" style="margin-top: 90px;margin-bottom: 120px;">
        <header-menu/>
        <div class="page-margin">
            <alert ref="userRecoveryAlert" :body="alert.message" :type="alert.type"/>
            <template v-if="uuid === null">
                <h2>Forgot Password</h2>
                <div class="row">
                    <div class="col-12">
                        <user-recovery-form :onlyRegister="true" @onSend="onSend" @onError="onError" @onCancel="onCancel"/>
                        <a href="/login" class="btn btn-link"> Back to login</a>
                    </div>
                </div>
            </template>
            <template v-else>
                <h2>Change Password</h2>
                <div class="row">
                    <div class="col-12">
                        <user-reset-password-form :uuid="uuid" @onReset="onReset" @onError="onError"/>
                        <a href="/login" class="btn btn-link"> Back to login</a>
                    </div>
                </div>
            </template>
        </div>
        <footer class="fixed-bottom container-fluid text-bg-light">
            <p class="text-center">South Florida School of Real Estate© 2023 • All Rights Reserved <span class="text-muted ml-4">v1.0.0</span></p>
        </footer>
    </div>
</template>

<script>
import headerMenu from './headerMenu'
import alert from '../../components/utilities/alert';
import userRecoveryForm from "../../components/app/user/user_recovery_form"
import userResetPasswordForm from "../../components/app/user/user_reset_password_form"
export default {
    name: 'user-crud',
    components: {
        'user-recovery-form' : userRecoveryForm,
        'user-reset-password-form' : userResetPasswordForm,
        'alert': alert,
        'header-menu' : headerMenu
    },
    methods:{
        openAlert(msg, type, timeOut = 0){
            this.alert.message = msg;
            this.alert.type = type;
            this.$refs['userRecoveryAlert'].open();
            setTimeout(() => {
                this.$refs['userRecoveryAlert'].close();
            }, timeOut);
        },
        onSend(evt){
            this.openAlert('A recovery message has been sent to the registered email account, you can update your password by clicking on the link provided.', 'success', 1500);
        },
        onReset(evt){
            this.openAlert('Updated Password succesfully', 'success', 1500);
        },
        onError(err){
            this.openAlert(`Error : ${err}`, 'danger', 3500);
        }
    },  
    computed:{
        type(){
            return this.$route.params.type;
        },
        id(){
            return this.$route.params.id||null;
        },
        uuid(){
            return this.$route.query.uuid?this.$route.query.uuid:null;
        }
    },
    data(){
        return {
            alert:{
                message: null,
                type: null
            }
        }
    }
}


</script>