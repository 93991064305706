<template>
    <div class="container">
        <div class="page-margin">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/users">Users</a></li>
                  <li class="breadcrumb-item"><a :href="`/user/details/${userId}`">{{ userName }}</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Certificates</li>
                </ol>
            </nav>
            <h2>Certificates <small class="text-muted">{{ userName }}</small></h2>
            <div class="card" style="margin-top:10px">
                <custom-table source="/certificate/list" :params="userId ? { 'userId': userId }: {}" ref="certificateList" :enableSearch="false">
                    <template #header>
                        <th style="width: 20%;">Course</th>
                        <th style="width: 65%;"></th>
                        <th style="width: 15%;">Download</th>
                    </template>
                    <template #body="{row, index}">
                        <td>
                            <div class="course-image-list" :style="`background-image: url(${getPath(row.course.image)})`">
                                <span class="sr-only">course image</span>
                            </div>
                        </td>
                        <td>
                            <span class="text-muted">{{ row.course.fullName }}</span>
                        </td>
                        <td>
                            <a :href="getPath(`certificate/${row._id}`, true)"  class="btn btn-link" title="Download certificate" download>  <i class="fa fa-download" aria-hidden="true"></i> </a>
                        </td>
                    </template>
                </custom-table>
            </div>
        </div>
    </div>
</template>

<script>
import table from '../../../components/utilities/table'
import Vuex from 'vuex'

export default {
    name: 'user-certificate-list',
    components:{
        'custom-table': table
    },
    computed:{
        ...Vuex.mapState({
            authToken: state => state.auth.token,
        }),
        userId(){
            return this.$route.params.userId ? this.$route.params.userId : null
        },
        userName(){
            return this.$route.query.name ? this.$route.query.name : 'User';
        }
    },
    mounted(){},    
    methods:{
        error(err){
            console.log(err);
        },
        getPath(path, addToken = false){
            return `${app.API}/${path}${addToken?`?token=${this.authToken}`:''}`;
        }
    }
}
</script>

<style>
.course-image-list{
    height: 5rem; 
    width: 20rem;
    background-position: center;
    background-size: cover;
}

</style>