<template>
  <div class="container-fluid home-background">
    <div class="row home-mobile-margin">
      <div class="col-md-6 simple-home-background-column">
        <h1 class="mb-4">Welcome to <br> South Florida School of Real Estate</h1>
        <p>
          We’re Florida’s premium provider of licensing and continuing education for Real Estate professionals since february 1999. Our instructors are Real Estate Specialist with many years of experience in the industry and understands the needs of students. Our courses are designed to provide you with all the tools and knowledge required to be a successful real estate licensing process. All our courses are also available online.
        </p>
      </div>
      <div class="col-md-6 simple-home-background-column">
        <div style="text-align: center;" wfd-id="437"><iframe src="https://player.vimeo.com/video/598394353?dnt=1&app_id=122963" class="simple-home-background-video" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" frameborder="0"></iframe></div>
      </div>
    </div>
  </div>
  </template>
  <script>
  export default {
    name: 'home',
  }
  </script>