export default {
  methods: {
    fileTypeIs(type, extension){
      let fileExtensions = [];
      switch (type) {
        case 'image':
          fileExtensions = ['bmp','jpg','jpeg','gif','png','eps','raw','nef'];
          break;
        case 'audio':
          fileExtensions = ['mp3','mpeg','wav','ogg','m4p','wav','webm'];
          break;
        case 'video':
          fileExtensions = ['webm','mkv','flv','ogv','ogg','avi','wmv','mp4', 'm2v', 'm4v'];
          break;
        case 'pdf':
          fileExtensions = ['pdf'];
          break;
        default:
          break;
      }
      return !!fileExtensions.find(ext => ext === extension);
    }
  }
}