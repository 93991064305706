<template>
    <div class="toast align-items-center border-0" :class="[classes]" style="position:fixed;z-index:99999"  :style="{'display':display}" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body" v-html="body"></div>
          <button type="button" @click.prevent="close" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'alert',
    props:{
        body:{
            type: String,
            default: null
        },
        type: {
            type : String,
            default: 'secondary'
        }
    },
    computed:{
        classes(){
            return `text-bg-${this.type}`;
        }
    },  
    methods:{
        open(){
            this.display = 'block !important'
        },
        close(){
            this.display = 'none !important'
        }
    },
    data(){
        return{
            display: 'none !important'
        }
    }
}   
</script>
