import axios from 'axios'
import store from '../../store'
import settings from '../../../config.js'

export default async(method, url, data = null, token, formData = false, fullResult = false, responseType = undefined, requestTimeout = 60000) => {
    try {
        let abortController = new AbortController()
        const timeout = setTimeout(() => {
            abortController.abort()
        }, requestTimeout)

        let host = settings.API ? settings.API : "http://localhost";
        let aux = !formData ? `${url}?_=${new Date().getTime()}` : url;
        let res = await axios({
            url: `${host}${aux}`,
            method: method ? method : 'POST',
            cache: false,
            data: !formData ? (data ? {...data, token: token ? token : undefined } : { token: token ? token : undefined }) : data,
            responseType: responseType, // important
            signal: abortController.signal
        })
        clearTimeout(timeout);
        return fullResult?res:res.data;
    } catch (error) {
        if (error.response && (error.response.status == 401 || error.response.data == '401')) {
            alert("Invalid Session Access, please Login again");
            store.dispatch('auth/logout');
            window.location.reload();
        }else if(error.code === 'ERR_NETWORK' || error.code === 'ERR_CANCELED' ){ 
            alert("You are offline now, Please check your connection and try again.");
            throw error;
        }else if (error.response) {
            throw error.response.data
        } else if (error.request) {
            throw error.request;
        } else {
            throw error.message;
        }
    }
}