<template>
    <div class="container-fluid" style="margin-top:70px;margin-bottom: 120px;">
        <header-menu/>

        <div class="row simple-home-background">
          <div class="col-md-6 simple-home-background-column">
            <h1 class="mb-4">Welcome to <br> South Florida School of Real Estate</h1>
            <p>
              We’re Florida’s premium provider of licensing and continuing education for Real Estate professionals since february 1999. Our instructors are Real Estate Specialist with many years of experience in the industry and understands the needs of students. Our courses are designed to provide you with all the tools and knowledge required to be a successful real estate licensing process. All our courses are also available online.
            </p>
          </div>
          <div class="col-md-6 simple-home-background-column">
            <div style="text-align: center;" wfd-id="437"><iframe src="https://player.vimeo.com/video/598394353?dnt=1&app_id=122963" class="simple-home-background-video" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" frameborder="0"></iframe></div>
          </div>
        </div>
       

        <div class="container mt-4">
          <h2 class="mb-3">Online Courses</h2>
          <h5 class="mb-3">
            The most wanted courses for begin your real estate business are here.
          </h5>
          <div class="row">
              <div class="col-12 col-md-4" v-for="course of courses">
                <div class="card mb-2">
                  <div class="card-body">
                    <div @click.prevent="$router.push(`/online-course/${course._id}`)" style="cursor:pointer">
                      <div class="public-course-image-list" :style="`background-image: url(${getCourseImage(course.image)})`">
                        <span class="sr-only">Course image</span>
                      </div>
                      <h6 class="card-title mt-2">{{ course.fullName }}</h6>
                      <h4 class="card-title"> $ {{ currencyFormat(course.price) }} </h4>
                    </div>
                    <router-link style="float:right" :to="`/register?courseId=${course._id}`" class="btn btn-primary"> Register and Order Now! </router-link>
                  </div>
                </div>
              </div>
          </div>
        </div>

        <footer class="fixed-bottom container-fluid text-bg-light">
            <p class="text-center">South Florida School of Real Estate© 2023 • All Rights Reserved <span class="text-muted ml-4">v1.0.0</span></p>
        </footer>
    </div>
</template>

<script>
import request from './../../helpers/request'
import headerMenu from './headerMenu'
export default {
  name: 'pHome',
  components: {
    'header-menu' : headerMenu,
  },
  mounted(){
    this.getCourses();
  },
  methods:{
    currencyFormat(price){
      return price ? parseFloat(price).toFixed(2) : price;
    },
    async getCourses(){
        try{
            let res = await request('POST', '/course/pList', {}, null);
            this.courses = res.records;
        }catch(err){
            console.log(err);
        }
    },
    getCourseImage(path){
        return `${app.API}/${path}`;
    }
  },
  data(){
    return{
        courses : []
    }
  }
}
</script>