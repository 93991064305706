<template>
  <header class="py-3 border-bottom shadow fixed-header">
    <div class="container-fluid d-flex">
      <a href="/home" class="link-dark text-decoration-none d-none d-md-block d-lg-block">
        <img src="../../../assets/images/full-logo.png" width="160" height="42"/>
      </a>
      <div class="dropdown d-block d-sm-block d-md-none d-lg-none">
        <a href="#" class="text-decoration-none btn btn-light btn-md" id="dropdownNavLink" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-bars" aria-hidden="true"></i>
        </a>
        <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownNavLink" style="">
          <li><router-link to="/user/courses" class="dropdown-item" v-if="validateRole(2)">My Courses</router-link></li>
          <li><router-link to="/user/certificates" class="dropdown-item" v-if="validateRole(2)">My Certificates</router-link></li>
          <li><hr class="dropdown-divider" v-if="validateRole(2)"></li>
          <li><router-link to="/courses" class="dropdown-item">Courses</router-link></li>
          <li><router-link to="/users" class="dropdown-item" v-if="validateRole(1)">Users</router-link></li>
          <li><router-link to="/about-us" class="dropdown-item">About Us</router-link></li>
          <li><router-link to="/contact-us" class="dropdown-item">Contact Us</router-link></li>
          <li>
            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_xclick">
              <input type="hidden" name="business" value="72PKKD3WCLA9Q">
              <input type="hidden" name="lc" value="US">
              <input type="hidden" name="item_name">
              <input type="hidden" name="item_number">
              <input type="hidden" name="amount">
              <input type="hidden" name="currency_code" value="USD">
              <input type="hidden" name="button_subtype" value="services">
              <input type="hidden" name="no_note" value="1">
              <input type="hidden" name="no_shipping" value="1">
              <input type="hidden" name="rm" value="1">
              <input type="hidden" name="return" :value="`${app.URL_SITE}/quick-payment/success`">
              <input type="hidden" name="cancel_return" :value="`${app.URL_SITE}/quick-payment/fail`">
              <input type="hidden" name="bn" value="PP-BuyNowBF:btn_buynow_LG.gif:NonHosted">
              <input type="submit" name="" class="dropdown-item" value="Quick Payment">
            </form>
          </li>
        </ul>
      </div>
      <a href="/home" class="link-dark text-decoration-none d-block d-sm-block d-md-none d-lg-none">
        <img src="../../../assets/images/full-logo.png" width="160" height="42"/>
      </a>

      <div class="d-none d-md-block" style="margin-left:20px">
        <ul class="nav">
          <li><router-link to="/user/courses" class="nav-link link-dark" v-if="validateRole(2)">My Courses</router-link></li>
          <li><router-link to="/user/certificates" class="nav-link link-dark" v-if="validateRole(2)">My Certificates</router-link></li>
          <li><router-link to="/courses" class="nav-link link-dark">Courses</router-link></li>
          <li><router-link to="/users" class="nav-link link-dark" v-if="validateRole(1)">Users</router-link></li>
          <li><router-link to="/about-us" class="nav-link link-dark">About Us</router-link></li>
          <li><router-link to="/contact-us" class="nav-link link-dark">Contact Us</router-link></li>
          <li>
            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_xclick">
              <input type="hidden" name="business" value="72PKKD3WCLA9Q">
              <input type="hidden" name="lc" value="US">
              <input type="hidden" name="item_name">
              <input type="hidden" name="item_number">
              <input type="hidden" name="amount">
              <input type="hidden" name="currency_code" value="USD">
              <input type="hidden" name="button_subtype" value="services">
              <input type="hidden" name="no_note" value="1">
              <input type="hidden" name="no_shipping" value="1">
              <input type="hidden" name="rm" value="1">
              <input type="hidden" name="return" :value="`${app.URL_SITE}/quick-payment/success`">
              <input type="hidden" name="cancel_return" :value="`${app.URL_SITE}/quick-payment/fail`">
              <input type="hidden" name="bn" value="PP-BuyNowBF:btn_buynow_LG.gif:NonHosted">
              <input class="btn btn-primary" type="submit" name="" value="Quick Payment">
            </form>
          </li>
        </ul>
      </div>

      <div class="dropdown" style="margin-left:auto">
        <a href="#" class="d-flex link-dark text-decoration-none" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
          <small class="d-none d-sm-block"> Welcome {{ authUser.fullName }} <br> <span class="text-muted"> {{  authUser.role === 1?'Admin':'Student' }}  </span> </small> &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp
          <span class="userinitials size-35"><i class="fa-solid fa-user"></i></span>
        </a>
        <ul class="dropdown-menu dropdown-menu-end shadow" aria-labelledby="dropdownUser2" style="">
          <li class="dropdown-item d-block d-sm-block d-md-none d-lg-none" style="background-color: #ebebeb;">
            <small> Welcome {{ authUser.fullName }} <br> <span class="text-muted"> {{  authUser.role === 1?'Admin':'Student' }}  </span> </small>
          </li>
          <li class="d-block d-sm-block d-md-none d-lg-none"><hr class="dropdown-divider"></li>
          <li><router-link to="/user/settings" class="dropdown-item">Settings</router-link></li>
          <li><hr class="dropdown-divider"></li>
          <li><a class="dropdown-item" href="" @click.prevent="logout">Sign out</a></li>
        </ul>
      </div>
    </div>
  </header>
</template>
  
<script>
import Vuex from 'vuex'
import validateRole from '.././../../mixins/validateRole'

export default {
  name: 'headerMenu',
  mixins: [validateRole],
  computed:{
  ...Vuex.mapState({
    authUser: state => state.auth.user,
    authToken: state => state.auth.token,
  })
  },
  methods: {
    logout(){
      this.$store.dispatch("auth/logout");
      window.location.reload();
    }
  },
  data(){
    return {
      app: app
    }
  }
}
</script>
  
<style>
  .fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    background-color: white;
  }

  li.nav-item a.router-link-active{
    color: #ffffff !important;
    font-weight: bold !important;
  }

  li.dropdown a.router-link-active{
    background-color: #dedede;
    color: black;
  }

  .userinitials {
    background-color: #e9ecef;
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #373a3c;
    font-weight: 400;
    margin-right: 0.25rem;
    width: 35px;
    height: 35px;
  }
  
  </style>