<template>
    <div class="container" style="margin-top: 112px;margin-bottom: 120px;">
        <header-menu/>
        <div class="page-margin">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/">Courses</router-link></li>
                  <li class="breadcrumb-item active" aria-current="page">{{ course.fullName }}</li>
                </ol>
            </nav>

            <h2>{{ course.fullName }} <span style="float:right"> $ {{ course.price }} </span> </h2> 
            <div class="row">
                <div class="col-12 course-image" :style="`background-image: url(${getCourseImage(course.image)})`">
                    <span class="sr-only">Course image</span>
                </div>
                <div class="col-12" v-html="course.description" />
            </div>

            <router-link style="float:right" :to="`/register?courseId=${course._id}`" class="btn btn-primary btn-lg"> $ {{ course.price }}  Register and Order Now! </router-link>
        </div>
        <spinner ref="courseFormSpinner"/>
        <footer class="fixed-bottom container-fluid text-bg-light">
            <p class="text-center">South Florida School of Real Estate© 2023 • All Rights Reserved <span class="text-muted ml-4">v1.0.0</span></p>
        </footer>
    </div>
</template>

<script>

import request from './../../helpers/request'
import spinner from './../../components/utilities/spinner'
import headerMenu from './headerMenu'

export default {
    name: 'pCourse-details',
    components:{
        'spinner':  spinner,
        'header-menu' : headerMenu
    },
    computed:{
        courseId(){
            return this.$route.params.id;
        }
    },
    async mounted(){
        await this.getCourse();
    },
    methods:{
        async getCourse(){
            try{
                this.$refs.courseFormSpinner.show();
                this.course = await request('POST', '/course/read', {courseId: this.courseId}, null);
                this.$refs.courseFormSpinner.hide();
            }catch(err){
                console.log(err);
            }
        },
        getCourseImage(path){
            return `${app.API}/${path}`;
        }
    },
    data(){
        return {
            course:{
                _id: null,
                fullName: null,
                shortName: null,
                firstName: null,
                active: true,
                description: '',
                image: null
            }
        }
    }
}
</script>

<style>
.course-image{
    height: 160px; 
    width: 100%;
    background-position: center;
    background-size: cover;
}

</style>