<template>
    <div class="input-group">
        <div style="position: relative;width: 100%;">
            <input
              :id="`inputFieldFile${_uid}`"
              type="file"
              :placeholder="placeholder"
              :required="required"
              :disabled="disabled"
              :readonly="readOnly"
              :multiple="multiple"
              :accept="accept"
              style="display: none;"
            >
            <div :id="`inputFileDrag${_uid}`" :class="[{'file-drag-container': !disabled, 'file-drag-container-disabled': disabled}]">
              <template v-if="!disabled">
                <a href="" @click.prevent="onClick"> <i class="fas fa-cloud-upload-alt"></i> Chosen Files </a> or Drop files here. <br> 
                <small v-if=" files.length > 0"> Added Files {{ files.length }}</small>
              </template>
              <template v-else>
                <span class="text-muted"> <i class="fas fa-cloud-upload-alt"></i> Chosen Files or Drop files here. </span>  <br>
              </template>
            </div>
            <div class="input-preview-file-container" :id="'inputFileList'+_uid"></div>
        </div>
    </div>
</template>
<script>
import fileTypeIs from '../../../mixins/fileTypeIs';
export default {
  name: 'file-input', 
  mixins: [fileTypeIs],
  props: {
    value:{
      type: Array,
      default:() => []
    },
    height:{
      type: String,
      default: '600px'
    },
    validation: {
      default: null
    },
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    helpText: {
      type: String,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: null
    },
    margin: {
      type: String,
      default: null
    },
    padding: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: null
    },
    imageHeight: {
      type: String,
      default: null
    },
    imageWidth: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: true
    },
    accept:{
      type: String,
      default: '*'
    }
  },
  watch: {
    files:{
      handler:function(val){
        this.previewFiles();
        this.$emit('onInput', val);
      },
      deep:true
    }
  },
  computed: {
    screenSizeId() {
      return app.layout.screenSizeId;
    },
    dataProviderName() {
      return this.$parent.dataProvider;
    },
    dataProvider() {
      return this.$parent.data_provider_ref;
    },
    inputType() {
      return this.$parent.type;
    },
    computedValidationStatus() {
      return this.isValid === null ? "none" : this.isValid === true ? "" : "is-invalid"; /*is-valid*/
    },
    containerClasses() {
      return `${this.inLine ? 'row' : ''} ${this.classes ? this.classes : ''}`;
    },
    labelClasses() {
      let sizeClass = this.size ? `col-form-label-${size}` : '';
      let inlineClass = this.inLine ? `${this.inlineLabelColClass} col-form-label` : ''
      return `${sizeClass} ${inlineClass}`;
    },
  },
  mounted() {
    if(window.FileReader){
      this.init();
    }
  },
  methods: {
    onBlur() {
      this.$emit('onBlur', this);
    },
    onFocus() {
      this.$emit('onFocus', this);
    },
    onClick() {
      this.fileselect.click();
      this.$emit('onClick', this);
    },
    onCancel() {
      this.fileselect.value = '';
      this.$emit('onCancel', this);
    },
    init() {
      this.fileselect = document.getElementById(`inputFieldFile${this._uid}`);
      this.filedrag = document.getElementById(`inputFileDrag${this._uid}`);
      // file select
      this.fileselect.addEventListener("change", this.fileSelectHandler, false);
      let xhr = new XMLHttpRequest();
      if (xhr.upload) {
        // file drop
        this.filedrag.addEventListener("dragover", this.fileDragHover, false);
        this.filedrag.addEventListener("dragleave", this.fileDragHover, false);
        this.filedrag.addEventListener("drop", this.fileSelectHandler, false);
      }
		},
    fileDragHover(e) {
      e.stopPropagation();
      e.preventDefault();
      console.log(e.type);
      if(e.type == "dragover"){
        e.target.classList.add("file-drag-container-dragover");
      }else{
        e.target.classList.remove("file-drag-container-dragover");
      }
    },
    fileSelectHandler(e) {
      this.fileDragHover(e);
      let files = e.target.files || e.dataTransfer.files;
      if(files && files.length > 0){
        for (let file of files) {
          if(this.multiple) this.files.push(file);
          else this.files = [file];
        }
      }
      this.$emit('onChange', this);
    },
    previewFiles(){
      let list = document.getElementById(`inputFileList${this._uid}`);
      list.innerHTML = '';
      // process all File objects
      for (let i = 0;  i < this.files.length; i++) {
        var file = this.files[i];
        var newFileDiv = document.createElement('div');
        newFileDiv.className = 'preview-file';

        let filePreviewImg = document.createElement('img');
        let fileExtension = file.name.split(".").pop();
        if( fileExtension == 'pdf'){
          filePreviewImg.src = require("../../../assets/images/pdf.jpeg");
        }else if(this.fileTypeIs('audio', fileExtension)){
          filePreviewImg.src = require("../../../assets/images/audio.png");
        }else if(this.fileTypeIs('video', fileExtension)){
          filePreviewImg.src = require("../../../assets/images/video.jpg");
        }else{
          filePreviewImg.src = window.URL.createObjectURL(file);
        }
        filePreviewImg.width = 105;
        filePreviewImg.height = 105;
        filePreviewImg.className = 'input-preview-file-img';
        newFileDiv.appendChild(filePreviewImg);
        //delete Button Icon
        let deleteBtn = document.createElement('a');
        let deleteBtnIcon = document.createElement('i');
        deleteBtnIcon.className = "fa fa-trash";
        deleteBtn.className = 'input-delete-file';
        deleteBtn.addEventListener('click', (event,file)=>{
          let e = event.target || event.srcElement;
          this.removeFile(i);
        }, false);
        deleteBtn.appendChild(deleteBtnIcon);
        newFileDiv.appendChild(deleteBtn);
        //end Delete
        list.appendChild(newFileDiv);
      }
    },
    removeFile(index){
      this.files.splice(index, 1);
    },
    clearFiles(){
      this.files = [];
    }
  },
  data() {
    return {
      isValid: null,
      validText: null,
      invalidText: null,
      id: null,
      fileselect: null,
      filedrag: null,
      files:this.value,
      selectedFileIndex: null,
      imageSrc: null,
      _uid: 123
    }
  }
}
</script>

<style>
  .file-drag-container{
    min-height:75px;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    padding: 1em 0;
    margin: 1em 0;
    color: #555;
    border: 2px dashed #555;
    border-radius: 7px;
  }

  .file-drag-container-disabled{
    min-height:75px;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    padding: 1em 0;
    margin: 1em 0;
    color: #555;
    border: 2px dashed #555;
    border-radius: 7px;
    background: #dadada;
  }

  .file-drag-container:hover{
    background: #dadada;
    cursor: grabbing;
  }

  .file-drag-container-dragover{
    color: #212121;
    border: 2px dashed #0064cc;
    background: #d2d2d2;
  }
  .input-preview-file-container{
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    margin: auto;
  }
  .input-preview-file{
    flex: 1 1 auto;
    display:inline-block;
    position:relative;
  }
  .input-preview-file a {
    position:absolute;
    bottom:5px;
    right:5px;
    background:blue;
    color:#FFF;
  }
  .input-preview-file-img{
    max-width: 100%;
    max-height: 100%;
    padding: .25rem;
  }
  .preview-file:hover .input-preview-file-img {
    opacity: 0.2;
    transition: .5s ease;
    background-color: rgba(0,0,0,0.23);
  }
  .preview-file {
    position: relative;
    float:left;
  }
  .preview-file .input-delete-file {
    position:absolute;
    top:0;
    right:0;
    width: 18px;
    height: 18px;
    font-size: 18px;
    cursor: pointer;
    margin:5px;
    color:red;
  }
  
  .preview-file:hover .input-delete-file {
    display: block;
  }

  .preview-file .input-edit-file {
    position:absolute;
    top:0;
    right:2;
    display: none;
    width: 18px;
    height: 18px;
    font-size: 18px;
    cursor: pointer;
    margin:5px;
    color:blue;
  }
  .preview-file:hover .input-edit-file {
    display: block;
  }
  
</style>