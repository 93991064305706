<template>
    <div v-for="(file, index) of files" style="display: flex; align-items: center; justify-content: center; margin:5px">
        <template v-if="fileTypeIs('pdf',file.type)">
            <iframe :id="`file-${contentId}-${index}`" style="border:1px solid #666CCC; height:90vh" :title="title" :src="fileSource(file)" frameborder="1" scrolling="auto" width="850" ></iframe>
        </template>

        <template v-else-if="fileTypeIs('image',file.type)">
            <img :src="fileSource(file)" :title="title" :alt="title">
        </template>

        <template v-else-if="fileTypeIs('audio',file.type)">
            <audio controls>
                <!-- <source :src="fileSource(file)" type="audio/wav">
                <source :src="fileSource(file)" type="audio/ogg"> -->
                <source :src="fileSource(file)" type="audio/mpeg">
                Your browser does not support the audio element.
            </audio>
        </template>

        <template v-else-if="fileTypeIs('video', file.type)">
            <video style="width: 80vh; height: 40vh;" controls>
                <!-- <source :src="fileSource(file)" type="video/webm">
                <source :src="fileSource(file)" type="video/ogg"> -->
                <source :src="fileSource(file)" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </template>
    </div>
</template>

<script>
import fileTypeIs from '../../../../mixins/fileTypeIs';
import Vuex from 'vuex'
export default {
    name: 'content-view-files',
    mixins:[fileTypeIs],
    props:{
        courseId:{
            type: String,
            required: true
        },
        title:{
            type: String,
            default: ''
        },
        contentId:{
            type: String,
            required: true
        },
        files:{
            type: Array,
            required: true
        }
    },
    computed:{
        ...Vuex.mapState({
            authToken: state => state.auth.token,
        }),
    }, 
    methods: {
        fileSource(file){
            return `${app.API}/file/${this.courseId}/${file.name}?token=${this.authToken}`;
        }
    },
}
</script>