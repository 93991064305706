<template>
    <div class="container">
        <div class="page-margin">
            <button type="button" 
                class="btn btn-primary" 
                @click="$router.push(`/question/course/${courseId}/content/${contentId}/create`)" 
                style="float:right;">New <i class="fas fa-plus"></i>
            </button>
            <h2>Questions</h2>
            <div class="card" style="margin-top:10px">
                <custom-table source="/question/list" ref="questionList" :enableSearch="true" :options="{'filters':{'courseId': courseId, 'contentId':contentId}}">
                    <template #header>
                        <th style="width: 20%;">Name</th>
                        <th style="width: 70%;">Text</th>
                        <th style="width: 10%;"></th>
                    </template>
                    <template #body="{row, index}">
                        <td>
                            <router-link class="btn btn-link" title="Show question" :to="`/question/course/${courseId}/content/${contentId}/read/${row._id}`" > {{ row.name }} </router-link> <br>
                        </td>
                        <td>
                            {{ row.text }}
                        </td>
                        <td>
                            <router-link 
                                class="btn btn-link btn-sm" 
                                title="Edit question" 
                                :to="`/question/course/${courseId}/content/${contentId}/read/${row._id}`">
                                <i class="fas fa-edit"></i>
                            </router-link>
                            <a class="btn btn-link btn-sm" title="Delete question" href="" @click.prevent="deleteQuestion(row._id)"><i class="fas fa-trash-alt"></i></a>
                        </td>
                    </template>
                </custom-table>
            </div>
        </div>
    </div>
</template>

<script>
import table from '../../../utilities/table'
import request from '../../../../helpers/request'
import Vuex from 'vuex'

export default {
    name: 'question-list',
    props:{
        courseId:{
            type: String,
            required: true
        },
        contentId:{
            type: String,
            required: true
        }
    },
    components:{
        'custom-table': table
    },
    computed:{
        ...Vuex.mapState({
            authToken: state => state.auth.token,
        })
    },
    mounted(){

    },    
    methods:{
        error(err){
            console.log(err);
        },
        async deleteQuestion(questionId){
            try{
                const confirm = window.confirm("Are you sure, of delete this question?")
                if(confirm){
                    await request('POST', '/question/delete', {questionId: questionId}, this.authToken);
                    this.getQuestions();
                }
            }catch(err){
                console.log(err);
            }
        },
        getQuestions(){
            this.$refs.questionList.getRecords();
        }
    },
    data(){
        return {}
    }
}
</script>