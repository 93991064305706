<template>
    <div class="container">
        <div class="page-margin">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/courses">Courses</a></li>
                  <li class="breadcrumb-item" v-if="courseId" ><a :href="`/course/details/${courseId}`"> {{ courseName }}</a></li>
                  <li class="breadcrumb-item active" aria-current="page"> Exams </li>
                </ol>
            </nav>
            <router-link :to="`/course/${courseId}/content/create?type=exam&examType=final`" type="button" class="btn btn-primary" style="float:right;">New exam <i class="fas fa-plus"></i></router-link>
            <h2>Exams</h2>
            <div class="card" style="margin-top:10px">
                <custom-table source="/exam/list" :params="{'courseId': courseId}" ref="examList" :enableSearch="true">
                    <template #header>
                        <th style="width: 40%;">Name</th>
                        <th style="width: 50%;">Description</th>
                        <th style="width: 10%;"></th>
                    </template>
                    <template #body="{row, index}">
                        <td>
                            <router-link :to="`/course/${courseId}/content/read/${row._id}?type=exam`" type="button" class="btn btn-link">{{ row.name }}</router-link>
                        </td>
                        <td>
                            {{ row.description }}
                        </td>
                        <td>
                            <router-link :to="`/course/${courseId}/content/read/${row._id}?type=exam`" type="button" class="btn btn-link"><i class="fas fa-edit"></i></router-link>
                            <a href="" @click.prevent="deleteExam(row._id)" type="button" class="btn btn-link"><i class="fas fa-trash"></i></a>
                        </td>
                    </template>
                </custom-table>
            </div>
        </div>
    </div>
</template>

<script>
import table from '../../../components/utilities/table'
import request from '../../../helpers/request'
import Vuex from 'vuex'

export default {
    name: 'courseUserList',
    components:{
        'custom-table': table
    },
    computed:{
        ...Vuex.mapState({ authToken: state => state.auth.token }),
        courseId(){
            return this.$route.params.courseId;
        },
        courseName(){
            return this.$route.query.name ? this.$route.query.name : 'Course';
        }
    },
    methods:{
        async deleteExam(contentId){
            try{
                const confirm = window.confirm("Are you sure, of delete this exam?")
                if(confirm){
                    await request('POST', '/content/delete', {contentId: contentId}, this.authToken);
                    this.$refs.examList.getRecords();
                }
            }catch(err){
                console.log(err);
            }
        },
    },
    data(){
        return {
        }
    }
}
</script>