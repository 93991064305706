<template>
  <router-view/>
</template>
<script>
export default {
  async mounted(){
    try{
      await this.$store.dispatch("auth/init");
    }catch(err){
      console.log(err);
    }
  }
}
</script>