<template>
    <div class="container">
        <div class="page-margin">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/users">Users</a></li>
                  <li class="breadcrumb-item active" aria-current="page">{{ user.fullName }}</li>
                </ol>
            </nav>
            <h2>User <small class="text-muted"> {{ user.fullName }}</small></h2>
            <div class="row">
                <div class="col-12 row">
                    <div class="col-12 col-md-4 mt-2" style="text-align: center">
                        <router-link class="btn btn-primary btn-lg d-block" title="Edit Settings user" :to="`/user/read/${userId}?name=${user.fullName}`" > <i class="fa-solid fa-gear"></i> <br> Edit Settings</router-link>
                    </div>

                    <div class="col-12 col-md-4 mt-2" style="text-align: center">
                        <router-link class="btn btn-info btn-lg d-block"  title="user Participants" :to="`/user/${userId}/courses?name=${user.fullName}`" ><i class="fa-solid fa-chalkboard-user"></i> <br> Enrolled Courses</router-link>
                    </div>

                    <div class="col-12 col-md-4 mt-2" style="text-align: center">
                        <router-link class="btn btn-secondary btn-lg d-block"  title="user Final Exams" :to="`/user/${userId}/certificates?name=${user.fullName}`" ><i class="fa-solid fa-graduation-cap"></i> <br> Certificates</router-link>
                    </div>
                </div>
            </div>
        </div>
        <spinner ref="userFormSpinner"/>
    </div>
</template>

<script>
import request from '../../../helpers/request'
import spinner from '../../../components/utilities/spinner'
import Vuex from 'vuex'
export default {
    name: 'user-details',
    mixins: [],
    components:{
        'spinner':  spinner
    },
    computed:{
        ...Vuex.mapState({
            authToken: state => state.auth.token,
        }),
        userId(){
            return this.$route.params.id;
        },
        userName(){
            return this.$route.query.name ? this.$route.query.name : 'User';
        }
    },
    async mounted(){
        await this.getUser();
    },
    methods:{
        async getUser(){
            try{
                this.$refs.userFormSpinner.show();
                this.user = await request('POST', '/user/read', {_id: this.userId}, this.authToken);
                this.user['fullName'] = `${this.user.firstName?this.user.firstName:''} ${this.user.middleName?this.user.middleName:''} ${this.user.lastName?this.user.lastName:''}`
                this.$refs.userFormSpinner.hide();
            }catch(err){
                console.log(err);
            }
        }
    },
    data(){
        return {
            user:{
                _id: null,
                fullName: null,
            }
        }
    }
}
</script>

<style>
.user-image{
    height: 160px; 
    width: 100%;
    background-position: center;
    background-size: cover;
}

</style>