<template>
    <div class="container">
        <div class="page-margin">
            <h2>User Settings</h2>
            <alert ref="userListAlert" :body="alert.message" :type="alert.type"/>
            <div class="row">
                <user-form :userId="user._id" @onSave="onSave" @onError="onError" @onCancel="onCancel"/>
            </div>
        </div>
    </div>
</template>

<script>
import alert from '../../../components/utilities/alert';
import userForm from "../../../components/app/user/user_form"
import Vuex from 'vuex'

export default {
    name: 'user-seetings',
    computed:{
        ...Vuex.mapState({
            user: state => state.auth.user,
            authToken: state => state.auth.token,
        })
    },
    components: {
        'user-form' : userForm,
        'alert': alert
    },
    methods:{
        openAlert(msg, type, timeOut = 0, redirectPath = null){
            this.alert.message = msg;
            this.alert.type = type;
            this.$refs['userListAlert'].open();
            setTimeout(() => {
                this.$refs['userListAlert'].close();
                if(redirectPath) this.$router.push(redirectPath);
            }, timeOut);
        },
        onRead(evt){
            this.userNumber = evt.number;
        },  
        onSave(evt){
            this.openAlert(`User Updated Succesfully`, 'success', 3000);
        },
        onError(err){
            this.openAlert(`Error : ${err}`, 'danger', 3500);
        },
        onCancel(){
            this.$router.push('/');
        }
    },
    data(){
        return {
            alert:{
                message: null,
                type: null
            },
            userNumber: null
        }
    }
}
</script>