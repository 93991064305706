<template>
    <form id="resetPasswordForm" @submit.prevent="submitResetPasswordForm">
        <div style="margin-top:15px">
            <div class="card">
                <div class="card-body row">
                    <div class="mb-3 col-12">
                        <label for="resetPassword_password">Password <span style="color:red">*</span> </label>
                        <div class="input-group">
                            <input
                                v-show="pType == 'password'" 
                                type="password" 
                                class="form-control" 
                                id="resetPassword_password" 
                                placeholder="**************" 
                                v-model="password" 
                                autocomplete="new-password"
                                minlength="6" 
                                required
                            >
                            <input
                                v-show="pType == 'text'" 
                                type="text" 
                                minlength="6"
                                class="form-control" 
                                id="resetPassword_password" 
                                placeholder="**************" 
                                v-model="password" 
                                autocomplete="new-password" 
                                required
                            >
                                <span class="input-group-text" @click.prevent="pType = switchType(pType)">&nbsp<i class="fa-solid fa-eye"></i> </span>
                        </div>
                    </div>

                    <div class="mb-3 col-12">
                        <label for="verifyPassword">Confirm Password <span style="color:red">*</span> </label>
                        <div class="input-group">
                            <input
                                v-show="cPType == 'password'"  
                                type="password" 
                                class="form-control" 
                                id="verifyPassword" 
                                placeholder="*******************" 
                                v-model="verifyPassword" 
                                autocomplete="new-password" 
                                required
                            >
                            <input
                                v-show="cPType == 'text'"  
                                type="text" 
                                class="form-control" 
                                id="verifyPassword" 
                                placeholder="*******************" 
                                v-model="verifyPassword" 
                                autocomplete="new-password" 
                                required
                            >
                            <span class="input-group-text" @click.prevent="cPType = switchType(cPType)">&nbsp<i class="fa-solid fa-eye"></i> </span>
                        </div>
                        <div class="invalid-feedback d-block" v-if="vpError"> The password Confirmation does not match </div>
                    </div>
                </div>
            </div>

            <div class="col-12 clearfix" style="margin-top:15px">
                <button type="submit" class="btn btn-primary float-end" @click="addValidation" > Save <i class="far fa-save"></i></button>
            </div>

        </div>
        <spinner ref="resetPasswordFormSpinner"/>
    </form>
</template>

<script>
import request from '../../../../helpers/request'
import spinner from '../../../utilities/spinner'

export default {
    name: "resetPasswordForm",
    components:{
        'spinner':  spinner
    },
    props:{
        uuid:{
            type: String,
            required: true
        }
    },
    methods:{
        addValidation(){
            let form = document.getElementById("resetPasswordForm");
            form.classList.add('was-validated');
        },
        async submitResetPasswordForm(){
            try{
                this.$refs.resetPasswordFormSpinner.show();
                if(this.password != this.verifyPassword){ 
                    this.vpError = true;
                    setTimeout(() => {
                        this.vpError = false;
                    }, 3000);
                    throw 'The password Confirmation does not match';
                }else{
                    await request('POST', '/reset/password', { uuid:this.uuid, password:this.password }, null);
                    this.$emit('onReset', {success: true});
                    this.$refs.resetPasswordFormSpinner.hide();
                }
            }catch(err){
                console.log(err);
                this.$emit('onError', err);
                this.$refs.resetPasswordFormSpinner.hide();
            }
        },

        switchType(currentType){
            if(currentType === 'password') return 'text';
            else return 'password';
        }
    },
    data(){
        return{
            vpError:false,
            password: null,
            verifyPassword: null,
            pType: 'password',
            cPType: 'password'
        }
    }
}
</script>