"use strict";
import request from './../request';
const validateToken = async (token) => {
    try{         
        let res = await request('POST', '/validate/token', {'token': token}, token);
        return res;
    }catch(err){
        console.log(err);
        throw err;
    }
}
export default validateToken;