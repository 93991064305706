<template>
    <div class="container">
        <div class="page-margin">
            <h2 class="text-capitalize">Content <small class="text-muted">{{ contentType }}</small> </h2>
            <div class="row">
                <div class="col-12">
                    <alert ref="contentListAlert" :body="alert.message" :type="alert.type"/>
                    <template v-if="contentId && contentType === 'exam'">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                              <button class="nav-link active" id="settings-tab" data-bs-toggle="tab" data-bs-target="#settings-tab-pane" type="button" role="tab" aria-controls="settings-tab-pane" aria-selected="true">Settings</button>
                            </li>
                            <li class="nav-item" role="presentation">
                              <button class="nav-link" id="questions-tab" data-bs-toggle="tab" data-bs-target="#questions-tab-pane" type="button" role="tab" aria-controls="questions-tab-pane" aria-selected="false">Questions</button>
                            </li>
                          </ul>
                          <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="settings-tab-pane" role="tabpanel" aria-labelledby="settings-tab" tabindex="0">
                                <content-form 
                                    :courseId="courseId" 
                                    :type="type" 
                                    :contentId="contentId" 
                                    :parentId="parentId" 
                                    :contentType="contentType"
                                    @onSave="onSave" 
                                    @onError="onError" 
                                    :enableAccount="true" 
                                    @onCancel="onCancel"/>
                            </div>
                            <div class="tab-pane fade" id="questions-tab-pane" role="tabpanel" aria-labelledby="questions-tab" tabindex="0">
                                <question-list :courseId="courseId" :contentId="contentId"/>
                            </div>
                          </div>
                    </template>
                    <template v-else>
                        <content-form :courseId="courseId" :type="type" :contentId="contentId" :parentId="parentId" :contentType="contentType"  @onSave="onSave" @onError="onError" :enableAccount="true" @onCancel="onCancel"/>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import alert from '../../../components/utilities/alert';
import contentForm from "../../../components/app/content/content_form"
import questionList from "../../../components/app/question/question_list"
export default {
    name: 'content-crud',
    components: {
        'content-form' : contentForm,
        'question-list':questionList,
        'alert': alert
    },
    methods:{
        openAlert(msg, type, timeOut = 0){
            return new Promise((resolve, reject) =>{
                this.alert.message = msg;
                this.alert.type = type;
                this.$refs['contentListAlert'].open();
                setTimeout(() => {
                    this.$refs['contentListAlert'].close();
                    resolve(true);
                }, timeOut);
            })
            
        },
        async onSave(evt){
            await this.openAlert(`content ${evt.type=='create'?'Created':'Updated'} Succesfully`, 'success', 1500);
            if(evt.contentType === 'exam' && evt.type === 'create'){
                this.$router.push(`/course/${evt.courseId}/content/read/${evt.contentId}?type=${evt.contentType}`);
            }else{
                window.history.back();
            }   
        },
        onError(err){
            this.openAlert(`Error : ${err}`, 'danger', 3500);
        },
        onCancel(evt){
            window.history.back();
        }
    },  
    computed:{
        type(){
            return this.$route.params.type;
        },
        courseId(){
            return this.$route.params.courseId;
        },
        parentId(){
            return this.$route.params.type=='create' && this.$route.params.contentId?this.$route.params.contentId:null;
        },
        contentId(){
            return this.$route.params.type=='read' && this.$route.params.contentId?this.$route.params.contentId:null;
        },
        contentType(){
            return this.$route.query.type ? this.$route.query.type : 'page';
        }
    },
    data(){
        return {
            alert:{
                message: null,
                type: null
            }
        }
    }
}


</script>