<template>
  <header class="py-3 border-bottom shadow fixed-header">
    <div class="container-fluid d-flex">
      <a href="/home" class="link-dark text-decoration-none d-none d-md-block d-lg-block">
        <img src="../../../assets/images/full-logo.png" width="160" height="42"/>
      </a>

      <div class="dropdown d-block d-sm-block d-md-none d-lg-none">
        <a href="#" class="text-decoration-none btn btn-light btn-md" id="dropdownNavLink" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-bars" aria-hidden="true"></i>
        </a>
        <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownNavLink" style="">
          <li><router-link to="/" class="dropdown-item">Home</router-link></li>
          <li><router-link to="/about-us" class="dropdown-item">About Us</router-link></li>
          <li><router-link to="/contact-us" class="dropdown-item">Contact Us</router-link></li>
          <li>
            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_xclick">
              <input type="hidden" name="business" value="72PKKD3WCLA9Q">
              <input type="hidden" name="lc" value="US">
              <input type="hidden" name="item_name">
              <input type="hidden" name="item_number">
              <input type="hidden" name="amount">
              <input type="hidden" name="currency_code" value="USD">
              <input type="hidden" name="button_subtype" value="services">
              <input type="hidden" name="no_note" value="1">
              <input type="hidden" name="no_shipping" value="1">
              <input type="hidden" name="rm" value="1">
              <input type="hidden" name="return" :value="`${app.URL_SITE}/quick-payment/success`">
              <input type="hidden" name="cancel_return" :value="`${app.URL_SITE}/quick-payment/fail`">
              <input type="hidden" name="bn" value="PP-BuyNowBF:btn_buynow_LG.gif:NonHosted">
              <input type="submit" name="" class="dropdown-item" value="Quick Payment">
            </form>
          </li>
        </ul>
      </div>

      <a href="/home" class="link-dark text-decoration-none d-block d-sm-block d-md-none d-lg-none">
        <img src="../../../assets/images/full-logo.png" width="160" height="42"/>
      </a>

      <div class="d-none d-md-block" style="margin-left:20px">
        <ul class="nav">
          <li><router-link to="/" class="nav-link link-dark">Home</router-link></li>
          <li><router-link to="/about-us" class="nav-link link-dark">About Us</router-link></li>
          <li><router-link to="/contact-us" class="nav-link link-dark">Contact Us</router-link></li>
          <li>
            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_xclick">
              <input type="hidden" name="business" value="72PKKD3WCLA9Q">
              <input type="hidden" name="lc" value="US">
              <input type="hidden" name="item_name">
              <input type="hidden" name="item_number">
              <input type="hidden" name="amount">
              <input type="hidden" name="currency_code" value="USD">
              <input type="hidden" name="button_subtype" value="services">
              <input type="hidden" name="no_note" value="1">
              <input type="hidden" name="no_shipping" value="1">
              <input type="hidden" name="rm" value="1">
              <input type="hidden" name="return" :value="`${app.URL_SITE}/quick-payment/success`">
              <input type="hidden" name="cancel_return" :value="`${app.URL_SITE}/quick-payment/fail`">
              <input type="hidden" name="bn" value="PP-BuyNowBF:btn_buynow_LG.gif:NonHosted">
              <input class="btn btn-primary" type="submit" name="" value="Quick Payment">
            </form>
          </li>
        </ul>
      </div>

      <div style="margin-left:auto">
        <ul class="nav">
          <li><router-link to="/login" class="nav-link link-dark" >Login</router-link></li>
          <li><router-link to="/register" class="nav-link link-dark" >Register</router-link></li>
        </ul>
      </div>
      
    </div>
  </header>
</template>
  
<script>
export default {
  name: 'pHeaderMenu',
  data(){
    return {
      app: app
    }
  }
}
</script>
  
<style>
  .fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    background-color: white;
  }

  li.nav-item a.router-link-active{
    color: #ffffff !important;
    font-weight: bold !important;
  }

  li.dropdown a.router-link-active{
    background-color: #dedede;
    color: black;
  }
  
  </style>